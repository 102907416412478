import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatBot } from "features/services/chat";

// register
export const chatResponseAsync = createAsyncThunk(
  "chat/chat-bot",
  async (userData) => {
    try {
      const response = await chatBot(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// export const askQuestionAsync = createAsyncThunk(
//   "chat/ask-question",
//   async (question) => {
//     try {
//       // const response = await askQuestion(userData);
//       resolve(question);
//     } catch (error) {
//       throw error;
//     }
//   }
// );