import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Provider } from "react-redux";
import store from "./features/store";
import { Toaster } from "react-hot-toast";
// import { GoogleOAuthProvider } from "@react-oauth/google";

import "./index.css";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      {/* <GoogleOAuthProvider clientId="417720805067-a7n9mlo7ciae5dfebflun1e12m1cdqlq.apps.googleusercontent.com"> */}
        <Provider store={store}>
          <Toaster />
          <App />
        </Provider>
      {/* </GoogleOAuthProvider> */}
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
