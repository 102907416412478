import axios from "axios";

import { config } from "../../constant";

const API_BASE_URL = config?.url?.API_URL;

// const apiInterceptor = () => {
const apiInterceptor = axios.create({
  baseURL: API_BASE_URL,
});

apiInterceptor.interceptors.request.use(
  (config) => {
    const userInfo = localStorage.getItem('token') ? localStorage.getItem('token') : "";
    if (userInfo) {
      config.headers["Authorization"] = `Bearer ${userInfo}`;
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
    // Add Content-Type header for form-urlencoded data

    return config;
  },
  (error) => {
    console.log(error, "error");
    return Promise.reject(error);
  }
);

apiInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;

    if (response) {
      const { data, status } = response;
      if (status === 401 && data?.detail === "Could not validate credentials") {
        localStorage.removeItem("token");
        window.location.href = "/sign-in";
      }
    }
    console.error("API Error:", error);
    return Promise.reject(error);
  }
);

// };

export default apiInterceptor;
