// react-router-dom components
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { NavLink } from "react-router-dom";

//added components
import { Checkbox } from "@mui/material";

// Authentication layout components
import PageLayout from "examples/LayoutContainers/PageLayout";
import logoHeader from "assets/images/color-logo.png";

// Images
import SignInImage from "assets/images/sign-in-bg.jpg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUserAsync, updateBusinessPagesAysnc } from "../../../features/actions/auth";
import Loader from "../../../assets/loader/loader";

import showHideIcon from "assets/images/small-logos/show-hide-icon.svg"
import dontShowIcon from "assets/images/small-logos/dont-show.svg"
import SignUpImage from "assets/images/sign-up-bg.jpg";


function Basic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [submit, setSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false);
  const { loginData, updateBusinessPages } = useSelector((state) => state.auth);
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const [values, setValues] = useState({
    email: email,
    businessGooglePage: "",
    businessFbPage: "",
    businessYelpPage: "",
    businessTwitterPage: "",

    // keepMeLogin: false,
  });
  const [errors, setErrors] = useState({

  });

  const eyeButton = () => {
    setShowPassword(!showPassword);
  }

  const validateForm = () => {
    const newErrors = {};
    const checkEmail = /\S+@\S+\.\S+/;
    const checkPassword = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    // Validate email
    if (!values.username) {
      newErrors.username = "Email is required";
    } else if (!checkEmail.test(values.username)) {
      newErrors.username = "Invalid email format";
    }

    // Validate password
    if (!values.password) {
      newErrors.password = "Password is required.";
    } else if (!checkPassword.test(values.password)) {
      newErrors.password = "Invalid password format";
    }

    return newErrors;
  };

  useEffect(() => {
    if (loginData?.data?.status_code == 200 || token) {
      navigate("/dashboard");
    }
  }, [loginData?.data, token]);

  const formDataObject = new FormData();
  Object.keys(values).forEach((key) => {
    formDataObject.append(key, values[key]);
  });

  useEffect(() => {
    if (submit) {
      if (Object.keys(errors).length === 0) {
        setLoading(true);
        // Dispatch the asynchronous action
        dispatch(updateBusinessPagesAysnc(formDataObject))
          .then((response) => {
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
      // setSubmit(false);
    }
  }, [submit]);

  const inputHandler = (fieldName) => {
    return (e) => {
      setValues({ ...values, [fieldName]: e.target.value });
    };
  };

  const handleSubmit = async () => {
    setSubmit(true);
  };

  useEffect(() => {
    if (updateBusinessPages.statusCode == 200) {
      navigate("/sign-in");
      localStorage.removeItem("email", values.email);
    }
  }, [updateBusinessPages])
  return (
    <PageLayout>
      {loading && <Loader />}
      <Grid
        container
        sx={{ height: "100vh", marginleft: "10px" }}
        justifyContent="space-between"
      >
        <Grid item md={6} px={5} sx={{ minHeight: "100vh", alignSelf: "center", justifyContent: "center", p: 2, width: "100%" }}>
          <Grid container>
            <Grid item xs={12}>
              <NavLink to="/">
                <MDBox sx={{ ml: 4, pt: 0, mt: 0 }}><img src={logoHeader} alt="profile_image" /></MDBox>
              </NavLink>
            </Grid>

            <Grid item xs={12}    >

              <MDBox sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", mx: "auto", }} >
                <MDBox  >
                  <MDBox px={1} pt={4} mt={2} sx={{
                    '@media (min-width:768px)':
                    {
                      paddingLeft: "2rem!important",
                      paddingRight: "2rem!important",
                    },
                    paddingLeft: "0!important",
                    paddingRight: "0!important",

                  }}>


                    <MDBox ml={1} mt={-2} py={1} mb={1} textAlign="left">
                      <Grid container sx={{ justifyContent: "center" }}>
                        <Grid item xs={12} lg={7} >
                          <MDTypography sx={{ fontSize: "36px", fontWeight: 700, lineHeight: "45.36px", color: "#212121" }} mt={1}>
                            Social Information
                          </MDTypography>

                          <MDTypography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "20.16px", color: "#838383" }} mt={1}>
                            Fill the form below to start the analysis
                          </MDTypography>
                        </Grid>
                      </Grid>


                    </MDBox>

                    <MDBox pt={1} pb={3} px={1} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      <MDBox component="form" role="form">
                        <Grid container sx={{ justifyContent: "center" }}>
                          <Grid item xs={12} lg={7} >
                            <MDBox p={1} mt={1}>
                              {/* <MDTypography variant="button" fontWeight="medium">
                              Google
                                  </MDTypography> */}
                              <MDInput
                                type="text"
                                placeholder="Google Page"
                                value={values.businessGooglePage}
                                error={!!errors.businessGooglePage}
                                onChange={inputHandler("businessGooglePage")}
                                
                                fullWidth
                                sx={{ "& .MuiInputBase-root":{borderRadius: '45px'}}}
                              />
                            </MDBox>
                            {errors.businessGooglePage ? (
                              <MDTypography px={1} variant="button" color="error">
                                {errors.businessGooglePage}
                              </MDTypography>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} lg={7} sx={{ alignSelf: "center", justifyContent: "center" }}>
                            <MDBox p={1} mt={1}>
                              {/* <MDTypography variant="button" fontWeight="medium">
                               Yelp
                                  </MDTypography> */}
                              <MDInput
                                type="text"
                                placeholder="Yelp Page"
                                value={values.businessYelpPage}
                                error={!!errors.businessYelpPage}
                                onChange={inputHandler("businessYelpPage")}
                                fullWidth
                                sx={{ "& .MuiInputBase-root":{borderRadius: '45px'}}}
                              />
                            </MDBox>
                            {errors.businessYelpPage ? (
                              <MDTypography px={1} variant="button" color="error">
                                {errors.businessYelpPage}
                              </MDTypography>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} lg={7} sx={{ alignSelf: "center", justifyContent: "center" }}>
                            <MDBox p={1} mt={1}>
                              {/* <MDTypography variant="button" fontWeight="medium">
                                Facebook
                                  </MDTypography> */}
                              <MDInput
                                type="text"
                                placeholder="Facebook Page"
                                value={values.businessFbPage}
                                error={!!errors.businessFbPage}
                                onChange={inputHandler("businessFbPage")}
                                fullWidth
                                sx={{ "& .MuiInputBase-root":{borderRadius: '45px'}}}
                              />
                            </MDBox>
                            {errors.businessFbPage ? (
                              <MDTypography px={1} variant="button" color="error">
                                {errors.businessFbPage}
                              </MDTypography>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} lg={7} sx={{ alignSelf: "center", justifyContent: "center" }}>
                            <MDBox p={1} mt={1}>
                              {/* <MDTypography variant="button" fontWeight="medium">
                                Twitter</MDTypography> */}
                              <MDInput
                                type="text"
                                placeholder="Twitter Page"
                                value={values.businessTwitterPage}
                                error={!!errors.businessTwitterPage}
                                onChange={inputHandler("businessTwitterPage")}
                                fullWidth
                                sx={{ "& .MuiInputBase-root":{borderRadius: '45px'}}}
                              />
                            </MDBox>
                            {errors.businessTwitterPage ? (
                              <MDTypography px={1} variant="button" color="error">
                                {errors.businessTwitterPage}
                              </MDTypography>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} lg={7} sx={{ alignSelf: "center", justifyContent: "center" }}>
                            <MDBox mt={4} mb={1}>
                              <MDButton color="info" variant="gradient" sx={{ fontFamily: "urbanist", fontSize: "16px", fontWeight: 700, lineHeight: "20.16px", color: "#ffffff",borderRadius:"45px" }} onClick={handleSubmit} fullWidth>
                                Get Started
                              </MDButton>
                            </MDBox>

                          </Grid>
                        </Grid>




                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>

            <Grid item xs={12}>
              <MDTypography sx={{ fontSize: "14px", fontWeight: 400, lineHeight: "16px", color: "#838383", textAlign: "center", pb: 0, mb: 0 }}>© 2024. All Rights Reserved.</MDTypography>
            </Grid>

          </Grid>
        </Grid>

        <Grid item md={6} sx={{ '@media (min-width:768px)': { display: "block" }, display: "none" }}>
          <MDBox
            sx={{
              minHeight: "100vh",
              height: "100%",
              borderRadius: "0 0 0 200px",
              overflow: "hidden",
              backgroundImage: `url(${SignUpImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <MDBox
              sx={{
                minHeight: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "60%"
                }}
              >
                <MDTypography
                  variant="h2"
                  fontWeight="regular"
                  sx={{ color: "#ffffff" }}
                  mt={1}
                  textAlign="center"
                >
                  Create Your
                </MDTypography>

                <MDTypography variant="h1" fontWeight="bold" sx={{ color: "#ffffff" }} mt={1} textAlign="center">
                  Review Attendant Account                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default Basic;
