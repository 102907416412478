import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React context
import { useMaterialUIController, setLayout } from "context";

function DashboardLayoutCustom2({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname, dispatch]);

  return (
    <MDBox sx={{ display: "flex", width: "100%", justifyContent: "end", alignItems: "end", }}>
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
         
          py: 2,
          
          position: "relative",

          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(0),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,

            }),
            width: "calc(100vw - 255px)",
            px:"1.00rem",
            
           
            




          },
        })}
      >
        {children}
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayoutCustom2.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayoutCustom2;
