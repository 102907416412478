// react-router-dom components
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//added components
import Header from "../../verify-email/header";
import { Container, Grid } from "@mui/material";

//images
import logoMain from "assets/images/logo-main.svg";
import worldIcon from "assets/images/small-logos/world-icon.svg";
import messageIcon from "assets/images/small-logos/message-icon.svg";
import callIcon from "assets/images/small-logos/call-icon.svg";

// Authentication layout components
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

function TermsAndConditions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = window.location.search;
  // const search = "https://yopmail.com/www.facebook.com/verify?email=ravi1@yopmail.com";

  //   const email = search?.split("?email=");

  //   useEffect(() => {
  //     if (email?.[1]) {
  //       dispatch(verifyEmailAsync(email?.[1]));
  //     }
  //   }, [dispatch, email]);

  //   const handleclick = () => {
  //     navigate("/sign-in");
  //   };

  return (
    <>
      <Header />
      <PageLayout>
        <MDBox
          sx={{
            minHeight: "100vh",
            textAlign: "start",
            maxWidth: "700px",
            margin: "0 auto",
            marginTop: "100px",
            marginBottom: "100px",
          }}
        >
          <MDBox sx={{ textAlign: "start" }}>
            <h1 style={{ fontSize: "54px", color: "#292929" }}>
              Terms & Conditions
            </h1>
          </MDBox>

          <h5 style={{ fontSize:"18px",fontWeight: 600,lineHeight:"30px",color:"#6b6b6b",textAlign: "end", margin: "20px 0px"}}>
            Last Updated: July 1, 2024{" "}
          </h5>
          <MDBox
            sx={{ textAlign: "start", color: "#589cf5", marginTop: "20px" }}
          >
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem"}}>Introduction </h3>
          </MDBox>
          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            Welcome to Review Attendant ("we," "our," or "us"). These Terms of
            Service ("Terms") govern your access to and use of our website, web
            application, and any related services (collectively, the "Service").
            By accessing or using the Service, you agree to be bound by these
            Terms. If you do not agree to these Terms, you must not access or
            use the Service.
          </p>

          <MDBox
            sx={{
              textAlign: "start",
              color: "#589cf5",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem"}}>Use of the Service </h3>
          </MDBox>

          <h4>Eligibility </h4>

          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            You must be at least 16 years old to use the Service. By using the
            Service, you represent and warrant that you meet this age
            requirement and have the legal capacity to enter into these Terms.{" "}
          </p>

          <h4 style={{ marginTop: "1rem" }}>Account Registration </h4>

          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            To access certain features of the Service, you may be required to
            create an account. You agree to provide accurate, current, and
            complete information during the registration process and to update
            such information to keep it accurate, current, and complete. You are
            responsible for maintaining the confidentiality of your account
            credentials and for all activities that occur under your account.{" "}
          </p>

          <h4 style={{ marginTop: "1rem" }}>User Conduct </h4>

          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            You agree not to use the Service for any purpose that is unlawful or
            prohibited by these Terms. You agree not to:
            <br />
            1. Use the Service in any manner that could disable, overburden, or
            impair the Service or interfere with any other party's use of the
            Service. <br />
            2. Attempt to gain unauthorized access to any part of the Service,
            other accounts, computer systems, or networks connected to the
            Service.
            <br />
            3. Use any automated means, such as robots, spiders, or data mining
            tools, to access, collect, or copy any content or information from
            the Service.
            <br />
            4. Use the Service to transmit, distribute, or store any material
            that is defamatory, infringing, obscene, or otherwise unlawful.
          </p>

          <h4 style={{ marginTop: "1rem" }}>Content </h4>
          <h5 style={{ marginTop: "0.5rem", marginBottom: "5px" }}>
            Your Content{" "}
          </h5>

          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            You retain ownership of any content you post, upload, or otherwise
            make available through the Service ("Your Content"). By making Your
            Content available through the Service, you grant us a non-exclusive,
            worldwide, royalty-free, fully paid-up, transferable, and
            sublicensable license to use, reproduce, modify, distribute,
            display, and perform Your Content in connection with the Service.{" "}
          </p>

          <h5 style={{ marginTop: "0.5rem", marginBottom: "5px" }}>
            Our Content
          </h5>
          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            All content and materials available on the Service, including but
            not limited to text, graphics, logos, icons, images, audio clips,
            video clips, and software, are the property of Review Attendant or
            its licensors and are protected by intellectual property laws. You
            agree not to reproduce, distribute, modify, create derivative works
            of, publicly display, publicly perform, republish, download, store,
            or transmit any of our content, except as expressly permitted by
            these Terms.{" "}
          </p>

       
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem",marginTop:"1.8rem"}}>Termination </h3>
          

          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            We reserve the right to terminate or suspend your account and access
            to the Service, without notice, for conduct that we believe violates
            these Terms or is harmful to other users, us, or third parties, or
            for any other reason in our sole discretion.{" "}
          </p>

          <MDBox
            sx={{ textAlign: "start", color: "#589cf5", marginTop: "20px" }}
          >
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem"}}>Disclaimers </h3>
          </MDBox>

          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            The Service is provided on an "as-is" and "as-available" basis. We
            make no representations or warranties of any kind, express or
            implied, regarding the operation or availability of the Service or
            the information, content, materials, or products included on the
            Service. To the fullest extent permissible by applicable law, we
            disclaim all warranties, express or implied, including but not
            limited to implied warranties of merchantability and fitness for a
            particular purpose.{" "}
          </p>

          <MDBox
            sx={{ textAlign: "start", color: "#589cf5", marginTop: "20px" }}
          >
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem"}}>Limitation of Liability </h3>
          </MDBox>

          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            To the fullest extent permissible by applicable law, in no event
            shall Review Attendant, its affiliates, licensors, or service
            providers be liable for any indirect, incidental, special,
            consequential, or punitive damages, or any loss of profits or
            revenues, whether incurred directly or indirectly, or any loss of
            data, use, goodwill, or other intangible losses, resulting from:{" "}
          </p>

          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            1. Your use or inability to use the Service. Any unauthorized access
            to or use of our servers and/or any personal information stored
            therein. <br />
            2. Any interruption or cessation of transmission to or from the
            Service. <br />
            3. Any bugs, viruses, Trojan horses, or the like that may be
            transmitted to or through the Service by any third party. <br />
            4. Any errors or omissions in any content or for any loss or damage
            incurred as a result of your use of any content posted, emailed,
            transmitted, or otherwise made available through the Service.
          </p>
          <MDBox
            sx={{ textAlign: "start", color: "#589cf5", marginTop: "20px" }}
          >
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem"}}>Indemnification </h3>
          </MDBox>
          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            You agree to indemnify, defend, and hold harmless Gratitude Systems,
            its affiliates, licensors, and service providers, and their
            respective officers, directors, employees, agents, and contractors,
            from and against any claims, liabilities, damages, judgments,
            awards, losses, costs, expenses, or fees (including reasonable
            attorneys' fees) arising out of or relating to your violation of
            these Terms or your use of the Service.{" "}
          </p>

          <MDBox
            sx={{
              textAlign: "start",
              color: "#589cf5",
              marginTop: "20px",
            }}
          >
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem"}}>Changes to These Terms </h3>
          </MDBox>
          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            We may update these Terms from time to time. If we make material
            changes, we will notify you by email or by posting a notice on the
            Service prior to the effective date of the changes. Your continued
            use of the Service after the effective date of the changes
            constitutes your acceptance of the revised Terms.
          </p>
          <MDBox
            sx={{ textAlign: "start", color: "#589cf5", marginTop: "20px" }}
          >
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem"}}>Governing Law </h3>
          </MDBox>
          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            These Terms and your use of the Service are governed by and
            construed in accordance with the laws of the District of Columbia,
            without regard to its conflict of law principles.
          </p>

          <MDBox
            sx={{ textAlign: "start", color: "#589cf5", marginTop: "20px" }}
          >
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem"}} >Contact Us </h3>
          </MDBox>
          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            If you have any questions about these Terms, please contact us at:{" "}
            <br />
            Email: info@gratitudesystems.com Address: 1050 Connecticut Ave, NW,
            Suite 500, Washington, DC 20036 Phone: +1 (202) 240-7754
          </p>
        </MDBox>

        <MDBox sx={{ backgroundColor: "#318BEC", py: 5 }}>
          <Container
            sx={{
              "@media (min-width:1580px)": { maxWidth: "1600px!important" },
            }}
          >
            <Grid container>
              <Grid item xxl={4} lg={3} md={6}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                    justifyContent: "start",
                  }}
                >
                  <MDBox>
                    <img src={logoMain} alt="logo" />
                  </MDBox>
                </MDBox>
              </Grid>

              <Grid item xxl={3} lg={4} md={6}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <MDBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MDBox
                      sx={{
                        mr: 0.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={worldIcon} alt="icon" />
                    </MDBox>

                    <MDBox>
                      <MDTypography
                        variant="button"
                        sx={{
                          color: "#ffffff",
                          fontSize: "16px",
                          lineHeight: "19px",
                          fontWeight: "700",
                        }}
                      >
                        WWW.GRATITUDESYSTEMS.COM
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item lg={3} md={6}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <MDBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MDBox
                      sx={{
                        mr: 0.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={messageIcon} alt="icon" />
                    </MDBox>

                    <MDBox>
                      <MDTypography
                        variant="button"
                        sx={{
                          color: "#ffffff",
                          fontSize: "16px",
                          lineHeight: "19px",
                          fontWeight: "700",
                        }}
                      >
                        INFO@GRATITUDESYSTEMS.COM
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Grid>

              <Grid item lg={2} md={6}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <MDBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MDBox
                      sx={{
                        mr: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        pr: 0,
                      }}
                    >
                      <img src={callIcon} alt="icon" />
                    </MDBox>

                    <MDBox>
                      <MDTypography
                        variant="button"
                        sx={{
                          color: "#ffffff",
                          fontSize: "16px",
                          lineHeight: "19px",
                          fontWeight: "700",
                        }}
                      >
                        (202)240-7754
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </Container>
        </MDBox>
      </PageLayout>
    </>
  );
}

export default TermsAndConditions;
