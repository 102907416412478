// react-router-dom components
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { MuiOtpInput } from "mui-one-time-password-input";
import { NavLink } from "react-router-dom";

import logoHeader from "assets/images/color-logo.png";
import SignInImage from "assets/images/sign-in-bg.jpg";
import { useEffect, useState } from "react";
import PageLayout from "examples/LayoutContainers/PageLayout";

function OtpVerification() {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [timer, setTimer] = useState(60);
  const [errorValue, setError] = useState("");
  const handleChange = (newValue) => {
    setError("");
    setValue(newValue);
  };

  useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  const handleSubmit = () => {
    if (value !== "") {
      if (value.length < 4) {
        setError("Invalid OTP");
      } else {
        setError("");

        navigate("/");
      }
    } else {
      setError("Please fill the OTP first");
    }
  };

  return (
    <PageLayout>
      <Grid
        container
        sx={{ minHeight: "100vh", marginleft: "10px" }}
        justifyContent="space-between"
      >
        <Grid item md={6} px={5} sx={{ alignSelf: "center", justifyContent: "center", width: "100%", height:"100%" }}>
          <Grid container sx={{ dispaly:"flex",justifyContent:"space-between",p:2,minHeight:"100vh" }}>
            <Grid item xs={12}>
              <NavLink to="/">
                <MDBox sx={{ ml: 4 }}>
                  <img src={logoHeader} alt="profile_image" />
                </MDBox>
              </NavLink>
            </Grid>
            <Grid item xs={12} sm={8} md={9} lg={8} xxl={7} sx={{ display: "flex", alignItems: "center", justifyContent: "center", mx: "auto" }}>

              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <MDBox sx={{ height: "100%" }}>
                  <MDTypography sx={{ fontSize: "36px", fontWeight: 700, lineHeight: "45.36px", color: "#212121" }} mt={1}>
                    Verify your account
                  </MDTypography>

                  <MDTypography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "20.16px", color: "#838383" }} mt={2}>
                    A one time password has been sent to your email
                  </MDTypography>

                  <MDBox pt={4} pb={3} px={2}>
                    <MDBox component="form" role="form">
                      <MDBox
                        mb={2}
                        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      >
                        <MuiOtpInput
                          length={4}
                          value={value}
                          onChange={handleChange}
                          sx={{ width: "270px", '&:.css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input': { fontSize: "36px", fontWeight: 700, lineHeight: "45.36px", color: "#212121" } }}
                        />
                      </MDBox>
                      <MDTypography variant="h6" color="error" mt={2} fontWeight="light">
                        {errorValue}
                      </MDTypography>
                      <MDBox mt={4} mb={1}>
                        <MDButton
                          onClick={() => handleSubmit()}

                          color="info"
                          sx={{
                            paddingLeft: "5rem",
                            paddingRight: "5rem",
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            borderRadius: "45px"
                          }}
                        >
                          Submit
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>

                  <MDBox display="flex" justifyContent="center" alignItems="center" >
                    {timer > 0 ? (
                      <>
                        <MDTypography variant="button" color="info" sx={{ textDecoration: "under-line" }} fontWeight="medium" textGradient>
                          Resend OTP in
                        </MDTypography>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"

                          ms={2}
                          sx={{ ml: 1, color: "#838383" }}
                        >
                          {timer >= 10 ? `00:${timer}` : `00:0${timer}`}
                        </MDTypography>
                      </>
                    ) : (
                      <MDBox mt={4} mb={1}>
                        <MDButton onClick={() => setTimer(60)} color="info" sx={{ borderRadius: "45px" }}>
                          Resend
                        </MDButton>
                      </MDBox>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>

            </Grid>
            <Grid item xs={12} >
              <MDTypography sx={{ fontSize: "14px", fontWeight: 400, lineHeight: "16px", color: "#838383", textAlign: "center", pb: 0, mb: 0 }}></MDTypography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={6}
          sx={{
            "@media (min-width:768px)": { display: "block" },
            display: "none",
          }}
        >
          <MDBox
            sx={{
              height: "100vh",
              borderRadius: "0 0 0 200px",
              overflow: "hidden",
              backgroundImage: `url(${SignInImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <MDBox sx={{ height: "100%" }}>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <MDTypography
                  variant="h2"
                  fontWeight="regular"
                  sx={{ color: "#ffffff" }}
                  mt={1}
                >
                  Welcome to
                </MDTypography>

                <MDTypography
                  variant="h1"
                  fontWeight="bold"
                  sx={{ color: "#ffffff" }}
                  mt={1}
                >
                  Review Attended
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </PageLayout >
  );
}

export default OtpVerification;
