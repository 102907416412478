import React, { useState } from "react";
import { Box, Modal, Typography, TextField, Autocomplete } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

const FilterModal = ({
  open,
  handleClose,
  attentionArr,
  sourceArr,
  ratingArr,
  replyArr,
  handleFilter,
  handleClearFilter,
  initialFilters,
  platform,
}) => {
  const [selectedAttention, setSelectedAttention] = useState(
    initialFilters.selectedAttention
  );
  const [selectedSource, setSelectedSource] = useState(
    initialFilters.selectedSource
  );
  const [selectedRating, setSelectedRating] = useState(
    initialFilters.selectedRating
  );
  const [selectedReply, setSelectedReply] = useState(
    initialFilters.selectedReply
  );
  const [authorName, setAuthorName] = useState(initialFilters.authorName);
  const [dateRange, setDateRange] = useState([
    initialFilters.selectedStartDate,
    initialFilters.selectedEndDate,
  ]);

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const onFilter = () => {
    handleFilter({
      selectedAttention,
      selectedSource,
      selectedRating,
      selectedReply,
      authorName,
      selectedStartDate: dateRange[0],
      selectedEndDate: dateRange[1],
    });
    handleClose();
  };

  const onClearFilter = () => {
    handleClearFilter();
    setSelectedAttention({
      label: "",
      value: "",
    });
    setSelectedSource(
      platform
        ? initialFilters.selectedSource
        : {
            label: "",
            value: "",
          }
    );
    setSelectedRating({
      label: "",
      value: "",
    });
    setSelectedReply({
      label: "",
      value: "",
    });
    setAuthorName("");
    setDateRange([null, null]);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "300px",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "12px",
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" sx={{ mb: 4 }}>
          Review Filter
        </Typography>
        <Autocomplete
          disablePortal
          options={attentionArr}
          getOptionLabel={(option) => option?.label}
          value={selectedAttention}
          onChange={(event, newValue) => setSelectedAttention(newValue)}
          sx={{ mt: 2, width: "100%" }}
          renderInput={(params) => (
            <TextField {...params} label="Needs Attention" />
          )}
        />

        <Autocomplete
          disablePortal
          options={sourceArr}
          getOptionLabel={(option) => option.label}
          value={selectedSource}
          disabled={platform}
          onChange={(event, newValue) => setSelectedSource(newValue)}
          sx={{ mt: 2, width: "100%" }}
          renderInput={(params) => <TextField {...params} label="Sources" />}
        />

        <Autocomplete
          disablePortal
          options={ratingArr}
          getOptionLabel={(option) => option.label}
          value={selectedRating}
          onChange={(event, newValue) => setSelectedRating(newValue)}
          sx={{ mt: 2, width: "100%" }}
          renderInput={(params) => <TextField {...params} label="Rating" />}
        />

        <Autocomplete
          disablePortal
          options={replyArr}
          getOptionLabel={(option) => option.label}
          value={selectedReply}
          onChange={(event, newValue) => setSelectedReply(newValue)}
          sx={{ mt: 2, width: "100%" }}
          renderInput={(params) => <TextField {...params} label="Reply Sent" />}
        />
        <TextField
          sx={{
            mt: 2,
            width: "100%",
            "& .MuiInputBase-root": { paddingBottom: "9px" },
          }}
          label="Author Name"
          variant="outlined"
          value={authorName}
          onChange={(event) => setAuthorName(event.target.value)}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            startText="Check-in"
            endText="Check-out"
            value={dateRange}
            sx={{ mt: 2, width: "100%" }}
            onChange={(newDateRange) => handleDateChange(newDateRange)}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <TextField {...endProps} />
              </>
            )}
          />
        </LocalizationProvider>
        <Box
          sx={{
            mt: 4,
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
          }}
        >
          <MDBox sx={{ mt: 1 }}>
            <MDButton variant="gradient" color="info" onClick={onFilter}>
              Submit
            </MDButton>
            <MDButton
              variant="outlined"
              color="secondary"
              onClick={onClearFilter}
              sx={{ ml: 2 }}
            >
              Reset
            </MDButton>
          </MDBox>
        </Box>
      </Box>
    </Modal>
  );
};

export default FilterModal;
