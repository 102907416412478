import { Container } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import styled from "@emotion/styled";

//images
import bgpattern from "assets/images/background-pattern.svg";
import tickIcon from "assets/images/small-logos/blue-tick.svg";
import dashboardHero from "assets/images/center-img.png";
import sparkles from "assets/images/small-logos/sparkles.svg";
import socialicons from "assets/images/social-group.png";

const BannerText = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #707070;
`;

function MainSection() {
  return (
    <MDBox sx={{ position: "relative", marginTop: "94px" }}>
      <MDBox sx={{ position: "absolute", left: "-20%", top: "-35%" }}>
        {" "}
        <MDBox sx={{ width: "803px", height: "803px", borderRadius: "803px", opacity: '0.1', background: "#318BEC", filter: "blur(150px)" }}></MDBox>
      </MDBox>
      <MDBox sx={{ position: "absolute", top: 0, left: 0 }}>
        {" "}
        <img src={bgpattern} alt="background pattern" />
      </MDBox>

      <Container sx={{ "@media (min-width:1580px)": { maxWidth: "1600px!important" } }}>
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <MDBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center!important",

            }}
          >
            <MDBox
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                "@media (min-width:475px)": { width: "90%" },
                width: "100%",
                textAlign: "center",
                "@media (max-width:576px)": { textAlign: "left" },

              }}
            >

              <MDBox sx={{ width: "100%", '@media(min-width:1100px)':{padding: "0 50px"}, "@media (min-width:336px)": { width: "90%" }, "@media (min-width:1500px)": { Width: "60%" }, marginBottom: "36px", marginTop: "70px", "@media (max-width:576px)": { marginTop: "32px", alignSelf: "center", justifySelf: "center" },'@media(min-width:1400px)':{padding:"0 180px"},'@media(min-width:1500px)':{padding:"0 60px"},'@media(min-width:1580px)':{padding:"0 200px"},'@media(max-width:576px)':{padding:"0 0px"},'@media(min-width:993px)':{width:"85%"} }}>
                <MDTypography
                  variant="h1"
                  fontWeight="medium"
                  sx={{ color: "#292929", fontFamily: "urbanist", fontWeight: "700", fontSize: '38px', lineHeight: "48px", '@media (min-width:420px)': { fontSize: '38px', lineHeight: "48px" }, '@media (min-width:580px)': { fontSize: '45px', lineHeight: "55px" }, '@media (min-width:1500px)': { fontSize: '60px', lineHeight: "70px" }, marginTop: "0" }}
                  mt={2}
                >
                  Unleash Your Review Potential  with
                  <MDTypography
                    variant="h2"
                    sx={{ color: "#318BEC", fontFamily: "urbanist", fontWeight: "700", fontSize: '38px', lineHeight: "48px", '@media (min-width:420px)': { fontSize: '38px', lineHeight: "48px" }, '@media (min-width:580px)': { fontSize: '45px', lineHeight: "55px" }, '@media (min-width:1500px)': { fontSize: '60px', lineHeight: "70px" } }}
                    mt={1}
                    mb={1}
                    component="span"
                    ml={1}
                  >
                    AI Review Attendant
                  </MDTypography>

                      <img src={sparkles} alt="icon"  style={{marginBottom:"20px"}}/>



                </MDTypography>
              </MDBox>

              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  marginBottom: "24px",
                  width: "85%",
                  textAlign: "center",
                  "@media (min-width:786px)": { flexDirection: "row" },
                  flexDirection: "column",
                }}
              >
                <MDBox
                  sx={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}
                  mr={2}
                  mb={2}
                >
                  <MDBox sx={{ mr: 1 }}><img src={tickIcon} alt="tick icon" /></MDBox>
                  <BannerText mb={2} sx={{ paddingLeft: "1rem" }}>
                    Improve your online review scores and make your customers happier
                  </BannerText>
                </MDBox>

                <MDBox
                  sx={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}
                  mr={2}
                  mb={2}
                >
                  <MDBox sx={{ mr: 1 }}><img src={tickIcon} alt="tick icon" /></MDBox>
                  <BannerText mb={2} ml={1}>
                    Unlock insights that can help you grow your business
                  </BannerText>
                </MDBox>

                <MDBox
                  sx={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}
                  mr={2}
                  mb={2}
                >
                  <MDBox sx={{ mr: 1 }}><img src={tickIcon} alt="tick icon" /></MDBox>
                  <BannerText mb={2} ml={1}>
                    Let Review Attendant take the burden of managing your online reviews
                  </BannerText>
                </MDBox>
              </MDBox>
            </MDBox>

            <MDBox sx={{ mb: 0, p: 0 }}>
              <img src={socialicons} alt="social icons" style={{ maxWidth: "100%", display: "block" }} />
            </MDBox>

            <MDBox sx={{ mt: 0, pt: 0, boxShadow: " rgba(0,0,0,0.1)", borderRadius: "8px", zIndex: 2, '@media(max-width:575px)': { marginBottom: "72px" } }}>
              <img src={dashboardHero} alt="dashboard " style={{ maxWidth: "100%" }} />
            </MDBox>
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
}

export default MainSection;
