import { useEffect, useLayoutEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import avatarIcon from "assets/images/avatar-icon.svg";
import searchInput from "assets/images/search-input.svg";
import { Paper } from "@mui/material";
import { Link } from "react-router-dom";
import logoutIcon from "assets/images/logout-icon.svg";

import reviewLogo from "assets/images/review-icon-dash.svg";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { useSelector } from "react-redux";
import zIndex from "@mui/material/styles/zIndex";
import { getProfileAysnc } from "features/actions/auth";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const navigate = useNavigate();
  // const match = useRouteMatch();
  const [controller, dispatch] = useMaterialUIController();
  const [scroll, setScroll] = useState(false);

  const { getprofile } = useSelector((state) => state?.auth);
  const { commonId } = useSelector((state) => state.dashboard);

  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const [logout, setLogout] = useState(false);

  const [facebookSideNav, setFacebookSideNav] = useState(null);
  const [xSideNav, setXSideNav] = useState(null);
  const [googleSideNav, setGoogleSideNav] = useState(null);
  const [yelpSideNav, setYelpSideNav] = useState(null);
  const [showSideBar, setShowSideBar] = useState(false);
  const [sidebarShow, setSidebarShow] = useState(false);
  const businessId = localStorage.getItem("common_id");
  const [dashboardSideNav, setDashboardSideNav] = useState(false);
  const [, setSidebarRoutes] = useState([]);
  let textColor = "white";
  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);
  useLayoutEffect(() => {
    if (routes) {
      const sidebarNewRoutes = routes?.filter((route) => {
        return route.name === "Dashboard";
      });
      setSidebarRoutes(sidebarNewRoutes);
    }
  }, [routes]);
  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1199);

      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1199 ? false : transparentSidenav
      );
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? true : whiteSidenav);
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location, transparentSidenav, whiteSidenav]);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/sign-in");
    setLogout(false);
  };

  useEffect(() => {
    if (logout) {
      handleLogout();
    }
  }, [logout]);

  useEffect(() => {
    if (scroll) {
      if (window.location.pathname === "/dashboard") {
        const bottomOffset = 1050; // Adjust this value as needed
        window.scrollTo({
          top: document.body.scrollHeight - bottomOffset,
          behavior: "smooth",
        });
      }
      setScroll(false);
    }
  }, [scroll]);

  useEffect(() => {
    if (getprofile?.businesses?.length > 0) {
      const businessId = localStorage.getItem("common_id");
      const business = getprofile?.businesses?.find(
        (x) => x?.common_id == businessId
      );
      if (business) {
        setDashboardSideNav(true);
        setFacebookSideNav(business?.facebook_reviews_count);
        setXSideNav(business?.twitter_reviews_count);
        setGoogleSideNav(business?.google_reviews_count);
        setYelpSideNav(business?.yelp_reviews_count);
      } else {
        setDashboardSideNav(false);
      }
    } else {
      setDashboardSideNav(false);
    }
  }, [getprofile, commonId]);

  return (
    <>
      {collapseName == "chatbot" ? (
        ""
      ) : (
        <SidenavRoot
          className="dashboard-sidenav"
          {...rest}
          variant="permanent"
          ownerState={{
            transparentSidenav,
            whiteSidenav,
            miniSidenav,
            darkMode,
          }}
        >
          <MDBox
            pt={3}
            pb={1}
            px={4}
            textAlign="center"
            className="customchangez-index"
          >
            <MDBox
              display={{ xs: "block", xl: "none" }}
              position="absolute"
              top={0}
              right={0}
              p={1.625}
              onClick={closeSidenav}
              sx={{ cursor: "pointer" }}
            >
              <MDTypography variant="h6" color="secondary">
                <Icon sx={{ fontWeight: "bold" }}>close</Icon>
              </MDTypography>
            </MDBox>
            {getprofile?.businesses?.length > 0 ? (
              <MDBox
                component={NavLink}
                // to="/"
                display="flex"
                alignItems="center"
              >
                {brand && (
                  <MDBox component="img" src={brand} alt="Brand" width="9rem" />
                )}
                <MDBox
                  width={!brandName && "100%"}
                  sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
                >
                  <MDTypography
                    component="h6"
                    variant="button"
                    fontWeight="medium"
                    color={textColor}
                  >
                    {brandName}
                  </MDTypography>
                </MDBox>
              </MDBox>
            ) : (
              <MDBox
                display="flex"
                alignItems="center"
                component={NavLink}
                to="/"
              >
                {brand && (
                  <MDBox component="img" src={brand} alt="Brand" width="9rem" />
                )}
                <MDBox
                  width={!brandName && "100%"}
                  sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
                >
                  <MDTypography
                    component="h6"
                    variant="button"
                    fontWeight="medium"
                    color={textColor}
                  >
                    {brandName}
                  </MDTypography>
                </MDBox>
              </MDBox>
            )}
          </MDBox>
          <Divider
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
          {/* <List>{renderRoutes}</List> */}
          <List sx={{ height: "100%" }}>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <MDBox>
                <>
                  {dashboardSideNav && (
                    <>
                      <NavLink
                        style={{
                          position:
                            collapseName === "dashboard"
                              ? "relative"
                              : "static",
                        }}
                        to={"/dashboard"}
                      >
                        <SidenavCollapse
                          name={"Dashboard"}
                          icon={<Icon fontSize="small">dashboard</Icon>}
                          active={"dashboard" === collapseName}
                        />
                        {collapseName === "dashboard" && (
                          <MDBox
                            sx={{
                              width: "4px",
                              height: "32px",
                              borderRadius: "25px",
                              backgroundColor: "#318BEC",
                              position: "absolute",
                              right: 0,
                              top: "8px",
                            }}
                          ></MDBox>
                        )}
                      </NavLink>

                      <NavLink
                        style={{
                          position:
                            collapseName === "dashboard"
                              ? "relative"
                              : "static",
                        }}
                        to={"/dashboard"}
                        onClick={() => setScroll(true)}
                      >
                        <SidenavCollapse
                          name={"Review"}
                          icon={<img src={reviewLogo}></img>}
                          active={"review" === collapseName}
                        />
                      </NavLink>
                    </>
                  )}
                  {dashboardSideNav && googleSideNav && (
                    <NavLink
                      style={{
                        position:
                          collapseName === "google-dashboard"
                            ? "relative"
                            : "static",
                      }}
                      to={"/google-dashboard"}
                    >
                      <SidenavCollapse
                        name={"Google"}
                        active={"google-dashboard" === collapseName}
                      />
                      {collapseName === "google-dashboard" && (
                        <MDBox
                          sx={{
                            width: "4px",
                            height: "32px",
                            borderRadius: "25px",
                            backgroundColor: "#318BEC",
                            position: "absolute",
                            right: 0,
                            top: "8px",
                          }}
                        ></MDBox>
                      )}
                    </NavLink>
                  )}
                  {dashboardSideNav && yelpSideNav && (
                    <NavLink
                      style={{
                        position:
                          collapseName === "yelp-dashboard"
                            ? "relative"
                            : "static",
                      }}
                      to={"/yelp-dashboard"}
                    >
                      <SidenavCollapse
                        name={"Yelp"}
                        // icon={<Icon fontSize="small">dashboard</Icon>}
                        active={"yelp-dashboard" === collapseName}
                      />
                      {collapseName === "yelp-dashboard" && (
                        <MDBox
                          sx={{
                            width: "4px",
                            height: "32px",
                            borderRadius: "25px",
                            backgroundColor: "#318BEC",
                            position: "absolute",
                            right: 0,
                            top: "8px",
                          }}
                        ></MDBox>
                      )}
                    </NavLink>
                  )}
                  {dashboardSideNav && facebookSideNav && (
                    <NavLink
                      style={{
                        position:
                          collapseName === "facebook-dashboard"
                            ? "relative"
                            : "static",
                      }}
                      key={"facebook"}
                      to={"/facebook-dashboard"}
                    >
                      <SidenavCollapse
                        name={"Facebook"}
                        // icon={<Icon fontSize="small">dashboard</Icon>}
                        active={"facebook-dashboard" === collapseName}
                      />
                      {collapseName === "facebook-dashboard" && (
                        <MDBox
                          sx={{
                            width: "4px",
                            height: "32px",
                            borderRadius: "25px",
                            backgroundColor: "#318BEC",
                            position: "absolute",
                            right: 0,
                            top: "8px",
                          }}
                        ></MDBox>
                      )}
                    </NavLink>
                  )}
                  {dashboardSideNav && xSideNav && (
                    <NavLink
                      style={{
                        position:
                          collapseName === "x-dashboard"
                            ? "relative"
                            : "static",
                      }}
                      key={"x"}
                      to={"/x-dashboard"}
                    >
                      <SidenavCollapse
                        name={"X"}
                        // icon={<Icon fontSize="small">dashboard</Icon>}
                        active={"x-dashboard" === collapseName}
                      />
                      {collapseName === "x-dashboard" && (
                        <MDBox
                          sx={{
                            width: "4px",
                            height: "32px",
                            borderRadius: "25px",
                            backgroundColor: "#318BEC",
                            position: "absolute",
                            right: 0,
                            top: "8px",
                          }}
                        ></MDBox>
                      )}
                    </NavLink>
                  )}
                </>
              </MDBox>
            </MDBox>
          </List>
        </SidenavRoot>
      )}
    </>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
