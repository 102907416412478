import React, { useEffect, useState, useRef } from "react";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import { config } from "constant";
import { gapi } from "gapi-script";
// import { GoogleLogin } from "react-google-login";
// import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

import Cookies from "js-cookie";
import PropTypes from "prop-types";
import googleIcon from "../../assets/images/icons/googleIcon.svg";

import toast from "react-hot-toast";
import TwitterLogin from "react-twitter-auth";
import FacebookLogin from "@greatsumini/react-facebook-login";
// import { googleTokenAsync } from "features/actions/auth";
// import { useDispatch, useSelector } from "react-redux";
import modallogo from "assets/images/icons/modal-logo.svg";
import modalcross from "assets/images/icons/modal-cross.svg";
import { useNavigate } from "react-router-dom";

const AuthModal = ({
  sourceAuthModal,
  setSourceAuthModal,
  setSourceModal,
  platform,
}) => {
  const CLIENT_ID = config?.url?.CLIENT_ID;
  const appId = config?.url?.Facebook_APPID;
  const navigate = useNavigate();
  // const [userData, setUserData] = useState(null);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  // const [sdkLoaded, setSdkLoaded] = useState(false);

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: CLIENT_ID,
        scope: "https://www.googleapis.com/auth/userinfo.profile", // Specify the scopes here
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);



  const handleAuthClick = () => {
    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then((response) => {
        const authResponse = response.getAuthResponse();
        if (authResponse?.access_token && authResponse.expires_in) {
          const expirationDate = new Date(
            new Date().getTime() + authResponse.expires_in * 1000
          );

          Cookies.set("googleAccessToken", authResponse?.access_token, {
            expires: expirationDate,
          });
          toast.success(`Authenticated successfully with ${platform}`);
          setSourceAuthModal(false);
          setSourceModal(false);
        }
      })
      .catch((error) => {
        console.error("Google Login Failed:", error);
      });
  };
  const fbRef = useRef(null);
  const onLoginSuccess = (data) => {
    console.log(data, "onsuccess")

  }

  const onLoginFailure = (data) => {
    console.log(data, "onsuccess fail")
  }
  // useEffect(() => {
  //   // Load the Facebook SDK
  //   (function (d, s, id) {
  //     var js, fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) return;
  //     js = d.createElement(s); js.id = id;
  //     js.src = "https://connect.facebook.net/en_US/sdk.js";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   }(document, 'script', 'facebook-jssdk'));

  //   // Initialize Facebook SDK
  //   window.fbAsyncInit = function () {
  //     window.FB.init({
  //       appId: config?.url?.Facebook_APPID, // Your Facebook App ID
  //       cookie: true,
  //       xfbml: true,
  //       version: 'v21.0' // Facebook API version
  //     });

  //     window.FB.AppEvents.logPageView();

  //   };

  //   // Check login status and set up the login button
  //   window.FB?.getLoginStatus((response) => {
  //     if (response.status === 'connected') {
  //       onLoginSuccess(response);

  //     }
  //   });

  //   // Clean up
  //   return () => {
  //     window.FB = null;
  //   };
  // }, [onLoginSuccess]);

  useEffect(() => {
    // Render Facebook login button after SDK is loaded
    if (fbRef.current) {
      window.FB?.XFBML.parse(fbRef.current);
    }
  }, [fbRef.current]);
  const onTwitterSuccess = (response) => {
    response.json().then((body) => {
      Cookies.set("xAccessToken", body.accessToken, { expires: 1 });
      // setUserData(body.user);
      toast.success(`Authenticated successfully with ${platform}`);
      setSourceAuthModal(false);
      setSourceModal(false);
    });
  };

  const onGoogleFailure = (res) => {
    console.log("login failure", res);
    setSourceAuthModal(false);
    setSourceModal(false);
    toast.error(`Login failed with ${platform}`);
  };
  const onTwitterFailure = (res) => {
    console.log("login failure", res);
    setSourceAuthModal(false);
    setSourceModal(false);
    toast.error(`Login failed with ${platform}`);
  };
  const handleFacebookResponse = (response) => {
    console.log("Facebook response received:", response);
    if (response.accessToken) {
      console.log("Access token:", response.accessToken);
      console.log("User ID:", response.userID);
      console.log("Name:", response.name);
      console.log("Email:", response.email);
      Cookies.set("fbAccessToken", response.accessToken, { expires: 1 });
      toast.success(`Authenticated successfully with ${platform}`);
      setSourceAuthModal(false);
      setSourceModal(false);
    } else {
      console.log("failed")
      toast.error(`Login failed with ${platform}`);
    }
  };

  // const handleFacebookFailure = (error) => {
  //   console.error("Facebook authentication error:", error);
  //   toast.error(`Login failed with ${platform}`);
  // };
  const [accessToken, setAccessToken] = useState("");

  // const handleLoginFacebook = async () => {
  //   try {
  //     const response = await fetch("https://graph.facebook.com/oauth/access_token", {
  //       params: {
  //         client_id: "4475162612701279",
  //         client_secret: "2cd7d516c9c25b6d04a2e8c092a8f486",
  //         grant_type: "client_credentials",
  //       },
  //     });
  //     setAccessToken(response?.data?.access_token);
  //     console.log(response, "resssss")
  //     // register();
  //   } catch (error) {
  //     throw error;
  //   }

  //   // let data = {
  //   //   token: accessToken,
  //   //   role: "buyer",
  //   //   social_type: "facebook",
  //   // };

  //   if (accessToken) {
  //     console.log(accessToken, "accesss_tokenn")
  //     // await dispatch(userGoogleAsync(data));
  //   }
  // };


  // const handleFacebookFailure = (error) => {
  //   // setError(error);
  //   console.error("Facebook authentication error:", error);
  // };
  const deleteAccessToken = () => {
    Cookies.remove("accessToken");
    toast.success("Logged out successfully");
  };

  return (
    <Modal
      open={sourceAuthModal}
      onClose={() => setSourceAuthModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <MDBox
        sx={{
          width: 420,
          bgcolor: "#ffffff",
          p: 3,
          "& button": { borderRadius: "45px" },
          borderRadius: "24px",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: "-7px",
            top: "-7px",
            cursor: "pointer",
          }}
          onClick={() => setSourceAuthModal(false)}
        >
          <img src={modalcross} alt="status icon" />
        </div>
        <div>
          <img src={modallogo} alt="status icon" />
          <h2
            id="modal-modal-title"
            style={{
              paddingTop: "1rem",
              paddingBottom: "2rem",
              color: "#318BEC",
              paddingRight: "3.8rem",
              fontSize: "32px",
              fontFamily: "urbanist",
              lineHeight: "42px",
            }}
          >
            Authenticate to access various features
          </h2>
          <div
            style={{
              border: `${platform === "Facebook"?'':'2px solid #d4cfc3'}`,
              padding: " 0.2rem 1rem",
              borderRadius: "45px",
              marginBottom: 2,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ overflow: "hidden", border: "1px solid white", cursor: "pointer" }}>
                {platform === "Google" ? (
                  <div style={{ display: "flex" }}>
                    <div>
                      <img
                        src={googleIcon}
                        alt="icon"
                        style={{ marginRight: "15px", marginTop: "10px" }}
                      />
                    </div>
                    <div onClick={handleAuthClick} style={{ marginTop: "7px" }}>
                      Authenticate with Google
                    </div>
                  </div>
                ) : platform === "X" ? (
                  <TwitterLogin
                    loginUrl="http://localhost:4000/api/v1/auth/twitter"
                    onFailure={onTwitterFailure}
                    onSuccess={onTwitterSuccess}
                    requestTokenUrl="http://localhost:4000/api/v1/auth/twitter/reverse"
                  />
                ) : platform === "Facebook" ? (
                  <FacebookLogin
                    appId={config?.url?.Facebook_APPID}
                    onSuccess={(response) => {
                      console.log('Login Success!', response);
                      if (response) {
                        const { accessToken } = response;
                        Cookies.set("fbAccessToken", accessToken, { expires: 1 });
                        onLoginSuccess(response);
                        toast.success("Authenticated successfully with Facebook");
                        setSourceAuthModal(false);
                        setSourceModal(false);
                        navigate('/facebook-dashboard')
                      }
                    }}
                    onFail={(error) => {
                      console.log('Login Failed!', error);
                      toast.error("Login failed with Facebook");
                    }}
                    onProfileSuccess={(response) => {
                      console.log('Get Profile Success!', response);
                    }}
                    style={{
                      backgroundColor: "#318BEC",
                      color: "#fff",
                      fontSize: "16px",
                      padding: "10px 20px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    Facebook Login
                  </FacebookLogin>
                ) : null}

              </div>
            </div>
          </div>
          <p
            style={{
              fontSize: "14px",
              color: "#7a7a78",
              marginTop: "2rem",
              textAlign: "center",
            }}
          >
            By proceeding, you agree to our{" "}
            <a href="/">
              <span
                className="underline-imp"
                style={{
                  textDecoration: "underline!important",
                  cursor: "pointer",
                  color: "#7a7a78",
                  "&:visited": { textDecoration: "underline!important" },
                  display: "inline",
                }}
              >
                Terms of Use
              </span>
            </a>{" "}
            and confirm you have read our{" "}
            <a href="/">
              <span
                className="underline-imp"
                style={{
                  textDecoration: "underline!important",
                  cursor: "pointer",
                  color: "#7a7a78",
                  display: "inline",
                }}
              >
                Privacy and Cookie Statement
              </span>
            </a>
            .
          </p>
        </div>
      </MDBox>
    </Modal>
  );

  {
    /* <GoogleLogin
                      clientId={CLIENT_ID}
                      buttonText={`Authenticate with ${platform}`}
                      onSuccess={onGoogleSuccess}
                      onFailure={onGoogleFailure}
                      cookiePolicy={"single_host_origin"}
                      isSignedIn={false}
                      // redirectUri="https://staging.reviewattendant.com"
                      redirectUri="http://localhost:3000/"
                    /> */
  }
};

AuthModal.propTypes = {
  sourceAuthModal: PropTypes.bool.isRequired,
  setSourceAuthModal: PropTypes.func.isRequired,
  setSourceModal: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
};

export default AuthModal;
