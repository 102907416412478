import { createSlice } from "@reduxjs/toolkit";
import {
  registerUserAsync,
  loginUserAsync,
  verifyEmailAsync,
  updateProfileAsync,
  getProfileAysnc,
  forgotPasswordAysnc,
  resetPasswordAysnc,
  updateBusinessPagesAysnc,
  getAllRestaurantsAysnc,
  GoogleSearchAysnc,
  GoogleSearchNearByAddressAysnc,
  getMyBusinessAysnc,
  getSingleBusinessAysnc,
  editBusinessAysnc,
  addBusinessAysnc,
  resendVerifyEmailAsync,
  googleTokenAsync,
  getGoogleReviewAysnc,
  getFacebookReviewAysnc,
  getYelpReviewAysnc,
  getTwitterReviewAysnc,
  scrapeUrlAsync,
  scrapeAllAsync,
  updateAllAsync,
  removeBusinessAsync,
  deleteAccountAsync,
} from "../../actions/auth";
import { getAllRestaurants } from "features/services/auth";

// import SecureLS from "secure-ls";

// const ls = new SecureLS();
// ls.set("token", "your-token-value");
// const token = ls.get("token");

const initialState = {
  status: "idle",
  registerData: {},
  loginData: {},
  verifyEmail: null,
  updateProfile: {},
  getprofile: {},
  authLoading: false,
  loading: false,
  error: null,
  forgotPassword: {},
  resetPassword: {},
  updateBusinessPages: {},
  getAllRestaurants: [],
  googleAddress: {},
  addressBasedOnLatLong: {},
  myBusinessList: [],
  getSingleBusiness: {},
  addNewBusiness: {},
  editBusiness: {},
  resendVerifyEmail: {},
  getGoogleToken: {},
  getFacebookData: {},
  getYelpData: {},
  getTwitterData: {},
  getScrapeUrl: {},
  dashboardFalse: false,
  dashboardFalse1: false,
  scrapeAllAsync: {},
  getScrapeUpdatedData: {},
  removeBusiness: {},
  deleteAccount: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearResetPasswordState: (state) => {
      state.resetPassword = {};
    },
    clearRegister: (state) => {
      state.registerData = {};
    },
    clearUpdateProfile: (state) => {
      state.getprofile = {};
    },
    clearGoogleAddress: (state) => {
      state.googleAddress = {};
    },
    clearAddressBasedOnLatLong: (state) => {
      state.addressBasedOnLatLong = {};
    },
    clearAddNewBusiness: (state) => {
      state.addNewBusiness = {};
    },
    clearFacebookReview: (state) => {
      state.getFacebookData = {};
    },
    clearEditBusiness: (state) => {
      state.editBusiness = {};
    },
    dashboardPage: (state, action) => {
      state.dashboardFalse = action.payload;
    },
    dashboardPage1: (state, action) => {
      state.dashboardFalse1 = true;
    },

    clearDashoardPage: (state) => {
      state.dashboardFalse = false;
    },
    resetGetScrapeUrl: (state) => {
      state.getScrapeUrl = {};
    },

    resetAuthSync: (state) => {
      state.getGoogleToken = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // register api cases
      .addCase(registerUserAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(registerUserAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.registerData = action.payload;
      })
      .addCase(registerUserAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      // login api cases
      .addCase(loginUserAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.loginData = action.payload;
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = action.payload; // Store the entire error information
      })

      // verify email api cases
      .addCase(verifyEmailAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(verifyEmailAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.verifyEmail = action.payload;
      })
      .addCase(verifyEmailAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(resendVerifyEmailAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(resendVerifyEmailAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.resendVerifyEmail = action.payload;
      })
      .addCase(resendVerifyEmailAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      // update profile api cases
      .addCase(updateProfileAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(updateProfileAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.updateProfile = action.payload;
      })
      .addCase(updateProfileAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      // get profile api cases
      .addCase(getProfileAysnc.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(getProfileAysnc.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.getprofile = action.payload;
      })
      .addCase(getProfileAysnc.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(deleteAccountAsync.pending, (state) => {
        state.loading = true;
        state.status = "idle";
      })
      .addCase(deleteAccountAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.loading = false;
        state.deleteAccount = action.payload;
      })
      .addCase(deleteAccountAsync.rejected, (state, action) => {
        state.status = "idle";
        state.loading = false;
        state.error = "invalid credential";
      })

      // forgot password
      .addCase(forgotPasswordAysnc.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(forgotPasswordAysnc.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.forgotPassword = action.payload;
      })
      .addCase(forgotPasswordAysnc.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      // reset password
      .addCase(resetPasswordAysnc.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(resetPasswordAysnc.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.resetPassword = action.payload;
      })
      .addCase(resetPasswordAysnc.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      // update Business media links
      .addCase(updateBusinessPagesAysnc.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(updateBusinessPagesAysnc.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.updateBusinessPages = action.payload;
      })
      .addCase(updateBusinessPagesAysnc.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(getAllRestaurantsAysnc.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(getAllRestaurantsAysnc.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.getAllRestaurants = action.payload;
      })
      .addCase(getAllRestaurantsAysnc.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(GoogleSearchAysnc.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(GoogleSearchAysnc.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.googleAddress = action.payload;
      })
      .addCase(GoogleSearchAysnc.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(GoogleSearchNearByAddressAysnc.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(GoogleSearchNearByAddressAysnc.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.addressBasedOnLatLong = action.payload;
      })
      .addCase(GoogleSearchNearByAddressAysnc.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(getGoogleReviewAysnc.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(getGoogleReviewAysnc.fulfilled, (state, action) => {
        state.status = "201";
        state.authLoading = false;
        state.addNewBusiness = action.payload;
      })
      .addCase(getGoogleReviewAysnc.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(getYelpReviewAysnc.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(getYelpReviewAysnc.fulfilled, (state, action) => {
        state.status = "201";
        state.authLoading = false;
        state.getYelpData = action.payload;
      })
      .addCase(getYelpReviewAysnc.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(getTwitterReviewAysnc.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(getTwitterReviewAysnc.fulfilled, (state, action) => {
        state.status = "201";
        state.authLoading = false;
        state.getTwitterData = action.payload;
      })
      .addCase(getTwitterReviewAysnc.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      .addCase(getFacebookReviewAysnc.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(getFacebookReviewAysnc.fulfilled, (state, action) => {
        state.status = "201";
        state.authLoading = false;
        state.getFacebookData = action.payload;
      })
      .addCase(getFacebookReviewAysnc.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(scrapeUrlAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(scrapeUrlAsync.fulfilled, (state, action) => {
        state.status = "201";
        state.authLoading = false;
        state.getScrapeUrl = action.payload;
      })
      .addCase(scrapeUrlAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(scrapeAllAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(scrapeAllAsync.fulfilled, (state, action) => {
        state.status = "201";
        state.authLoading = false;
        state.getScrapeData = action.payload;
      })
      .addCase(scrapeAllAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(updateAllAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(updateAllAsync.fulfilled, (state, action) => {
        state.status = "201";
        state.authLoading = false;
        state.getScrapeUpdatedData = action.payload;
      })
      .addCase(updateAllAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(getMyBusinessAysnc.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(getMyBusinessAysnc.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.myBusinessList = action.payload;
      })
      .addCase(getMyBusinessAysnc.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(getSingleBusinessAysnc.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(getSingleBusinessAysnc.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.getSingleBusiness = action.payload;
      })
      .addCase(getSingleBusinessAysnc.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(editBusinessAysnc.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(editBusinessAysnc.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.editBusiness = action.payload;
      })
      .addCase(editBusinessAysnc.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(googleTokenAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(googleTokenAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.getGoogleToken = action.payload;
      })
      .addCase(googleTokenAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(removeBusinessAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(removeBusinessAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.removeBusiness = action.payload;
      })
      .addCase(removeBusinessAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      });
  },
});
export const {
  clearResetPasswordState,
  clearUpdateProfile,
  clearGoogleAddress,
  clearAddNewBusiness,
  clearFacebookReview,
  clearAddressBasedOnLatLong,
  clearEditBusiness,
  clearRegister,
  dashboardPage,
  clearDashoardPage,
  dashboardPage1,
  resetGetScrapeUrl,
  resetAuthSync,
} = authSlice.actions;
export default authSlice.reducer;
