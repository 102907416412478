import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import dropdownIcon from "assets/images/dropdown-icon.svg";
import { useDispatch } from "react-redux";
import { itemCountAsync } from "features/actions/dashboard";
import Loader from "assets/loader/loader";
import {
  itemsArr,
  yearArr,
  sourceAbbr,
} from "examples/commonFolder/commonfolder";
import PropTypes from "prop-types";
import { serviceAsync } from "features/reducers/dashboard";
import refreshIcon from "assets/images/icons/refresh.svg";
import Tooltip from "@mui/material/Tooltip";

export default function CustomizedMenus1({ optionsDough }) {
  const dispatch = useDispatch();
  const [anchorElYear, setAnchorElYear] = useState(null);
  const [anchorElSentiment, setAnchorElSentiment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showYear, setShowYear] = useState("");
  const [showItem, setShowItem] = useState("");
  const [payload, setPayload] = useState("");
  const businessId = localStorage.getItem("common_id");
  const sourcePayload = sourceAbbr[optionsDough] || "";

  const selectedYearLabel =
    yearArr?.find((year) => year?.value === showYear)?.label || "Overall Items";

  const selectedItemLabel =
    itemsArr?.find((item) => item?.value == showItem)?.label || "Food";

  const handleYearFilter = (year) => {
    setShowYear(year);
    setPayload(sourcePayload);
  };

  const handleItemFilter = (item) => {
    setShowItem(item);
    setPayload(sourcePayload);
  };

  useEffect(() => {
    if (payload || showItem || showYear) {
      setLoading(true);
      dispatch(serviceAsync({ topTrend: showItem, year: showYear }));
      dispatch(
        itemCountAsync({
          id: businessId,
          service_type: showItem || "food",
          source: payload,
          year: showYear,
        })
      )?.finally(() => setLoading(false));
    }
    setAnchorElYear(null);
    setAnchorElSentiment(null);
  }, [payload, showItem, showYear]);

  const resetFilter = async () => {
    if (showYear || showItem) {
      setLoading(true);
      try {
        setShowYear("");
        setShowItem("");
        await dispatch(
          itemCountAsync({
            id: businessId,
            service_type: "food",
            source: payload,
            year: "",
          })
        );
      } catch (error) {
        console.error("Error occurred:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <MDBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "30px",
          marginRight: "10px",
          "@media(min-width:420px)": {
            gap: "30px",
            marginRight: 0,
          },
        }}
      >
        <div>
          <Button
            id="demo-customized-button-year"
            aria-controls={
              anchorElSentiment ? "demo-customized-menu-year" : undefined
            }
            aria-haspopup="true"
            aria-expanded={anchorElSentiment ? "true" : undefined}
            variant="contained"
            disableElevation
            disableRipple
            onClick={(event) => setAnchorElSentiment(event.currentTarget)}
            sx={{
              background: "none !important",
              "&:hover": { background: "none !important" },
              "&:active": { background: "none !important" },
              "&:focus": { background: "none !important" },
              padding: 0,
              margin: 0,
            }}
          >
            <MDBox
              sx={{
                display: "flex",
                alignitems: "center",
                justifyContent: "space-between",
                width: "65px",
                borderRadius: "100px",
                border: "1px solid #318BEC",
                padding: "6px 10px",
                backgroundColor: "#F0F7FF",
                cursor: "pointer",
                "&:hover": {
                  boxShadow:
                    "0rem 0.875rem 1.625rem -0.75rem rgba(26, 115, 232, 0.4), 0rem 0.25rem 1.4375rem 0rem rgba(26, 115, 232, 0.15), 0rem 0.5rem 0.625rem -0.3125rem rgba(26, 115, 232, 0.2)",
                },
              }}
            >
              <MDTypography
                sx={{
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "17px",
                  color: "#318BEC",
                }}
              >
                {selectedItemLabel}
              </MDTypography>
              <MDBox sx={{ display: "flex", alignitems: "center" }}>
                <img
                  src={dropdownIcon}
                  alt="dropdown icon"
                  style={{ maxWidth: "100%" }}
                />
              </MDBox>
            </MDBox>
          </Button>
          <Menu
            sx={{
              "& .MuiPaper-root ": {
                padding: 0,
                borderRadius: "14px",
                boxShadow: 9,
              },
            }}
            id="demo-customized-menu-year"
            anchorEl={anchorElSentiment}
            open={Boolean(anchorElSentiment)}
            onClose={() => {
              setAnchorElYear(null);
              setAnchorElSentiment(null);
            }}
          >
            {itemsArr?.map((item) => {
              return (
                <MenuItem
                  sx={{
                    padding: "10px 8px",
                    borderRadius: "0",
                    "& .MuiTypography-root": {
                      fontWeight: "700",
                      fontSize: "16px",
                      lineHeight: "19.2px",
                      color: "#292929",
                      fontFamily: "urbanist",
                    },
                    "&:hover": {
                      "& .MuiTypography-root": { color: "#ffffff" },
                      backgroundColor: "#318BEC",
                    },
                  }}
                  key={item.value}
                  selected={showItem === item?.value}
                  onClick={() => handleItemFilter(item?.value)}
                >
                  <Typography>{item?.label}</Typography>
                </MenuItem>
              );
            })}
          </Menu>
        </div>

        <div>
          <Button
            id="demo-customized-button-sentiment"
            aria-controls={
              anchorElYear ? "demo-customized-menu-sentiment" : undefined
            }
            aria-haspopup="true"
            aria-expanded={anchorElYear ? "true" : undefined}
            variant="contained"
            disableElevation
            disableRipple
            onClick={(event) => setAnchorElYear(event.currentTarget)}
            sx={{
              background: "none !important",
              "&:hover": { background: "none !important" },
              "&:active": { background: "none !important" },
              "&:focus": { background: "none !important" },
              padding: 0,
              margin: 0,
            }}
          >
            <MDBox
              sx={{
                display: "flex",
                alignitems: "center",
                justifyContent: "space-between",
                width: "auto",
                borderRadius: "100px",
                border: "1px solid #318BEC",
                padding: "6px 10px",
                backgroundColor: "#F0F7FF",
                cursor: "pointer",
                "&:hover": {
                  boxShadow:
                    "0rem 0.875rem 1.625rem -0.75rem rgba(26, 115, 232, 0.4), 0rem 0.25rem 1.4375rem 0rem rgba(26, 115, 232, 0.15), 0rem 0.5rem 0.625rem -0.3125rem rgba(26, 115, 232, 0.2)",
                },
              }}
            >
              <MDTypography
                sx={{
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "17px",
                  color: "#318BEC",
                }}
              >
                {selectedYearLabel}
              </MDTypography>
              <MDBox sx={{ display: "flex", alignitems: "center", ml: 0.5 }}>
                <img
                  src={dropdownIcon}
                  alt="dropdown icon"
                  style={{ maxWidth: "100%" }}
                />
              </MDBox>
            </MDBox>
          </Button>

          <Menu
            sx={{
              "& .MuiPaper-root ": {
                padding: 0,
                borderRadius: "14px",
                height: "250px",
                overflowY: "auto",
                boxShadow: 9,
              },
              "& .MuiPaper-root::-webkit-scrollbar ": { width: "7px" },
              "& .MuiPaper-root::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 5px grey",
                borderRadius: "10px",
              },
              "& .MuiPaper-root::-webkit-scrollbar-thumb": {
                background: "grey",
                borderRadius: "10px",
              },
            }}
            id="demo-customized-menu-year"
            anchorEl={anchorElYear}
            open={Boolean(anchorElYear)}
            onClose={() => {
              setAnchorElYear(null);
              setAnchorElSentiment(null);
            }}
          >
            {yearArr
              ?.slice()
              ?.reverse()
              ?.map((year) => {
                return (
                  <MenuItem
                    sx={{
                      padding: "10px 8px",
                      borderRadius: "0",
                      "& .MuiTypography-root": {
                        fontWeight: "700",
                        fontSize: "16px",
                        lineHeight: "19.2px",
                        color: "#292929",
                        fontFamily: "urbanist",
                      },
                      "&:hover": {
                        "& .MuiTypography-root": { color: "#ffffff" },
                        backgroundColor: "#318BEC",
                      },
                    }}
                    key={year?.value}
                    selected={showYear === year?.value}
                    onClick={() => handleYearFilter(year?.value)}
                  >
                    <Typography>{year?.label}</Typography>
                  </MenuItem>
                );
              })}
          </Menu>
        </div>

        <div>
          <Tooltip title="Reset Filter" placement="top">
            <button
              onClick={resetFilter}
              style={{
                padding: 0,
                margin: 0,
                border: "none",
                background: "none",
                cursor: "pointer",
                p: 1,
              }}
            >
              <img src={refreshIcon} alt="icon" />
            </button>
          </Tooltip>
        </div>
      </MDBox>
    </>
  );
}

CustomizedMenus1.propTypes = {
  optionsDough: PropTypes.array.isRequired,
};
