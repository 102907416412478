import axios from "axios";
// import ApiInterceptor from "../ApiInterceptor";
import { config } from "../../../constant";
import { toast } from "react-hot-toast";


const API_BASE_URL = config?.url?.API_URL;



export const chatBot = (userData) => {
  return new Promise((resolve, reject) => {
    let config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
    };
    axios
      .post(API_BASE_URL + 'chatbot/process_answer', userData, config)
      .then((response) => {
        resolve(response?.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

