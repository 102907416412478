import Header from "./header";

import EmailVerification from "./body";
function ThankYou() {
    return (
    <>

        <Header />
        <EmailVerification  />
        
    </>
    )
}

export default ThankYou;