import { createSlice } from "@reduxjs/toolkit";
import { askQuestionAsync } from "features/actions/chat";
import { chatResponseAsync } from "features/actions/chat";


const initialState = {
  status: "idle",
  chatResponse: {},
  askQuestion :"",
  chatLoading:false
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
      clearResetPasswordState: (state) => {
        state.resetPassword = {};
      },
      askQuestionState: (state, action) => {
        state.askQuestion = action?.payload;
      },
  },
  extraReducers: (builder) => {
    builder
      // register api cases
      .addCase(chatResponseAsync.pending, (state) => {
        state.chatLoading = true;
        state.status = "idle";
      })
      .addCase(chatResponseAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.chatLoading = false;
        state.chatResponse = action.payload;
      })
      .addCase(chatResponseAsync.rejected, (state, action) => {
        state.status = "idle";
        state.chatLoading = false;
        state.error = "invalid credential";
      })





  },
});
export const { clearResetPasswordState, askQuestionState } = chatSlice.actions;
export default chatSlice.reducer;
