import React from "react";
import { Bar, Line } from "react-chartjs-2";
import { Chart } from "chart.js/auto"; // Import Chart from 'chart.js/auto' instead of 'chart.js'
import MDTypography from "components/MDTypography";

const MultiColumnChart = ({
  multiColumnChartCategories = {},
  multiChartData = {},
}) => {

  // Extracting labels and datasets from multiChartData
  const labels =
    Object.keys(multiColumnChartCategories)?.length > 0 &&
    multiColumnChartCategories?.map((category) => category);
  const datasets = multiChartData?.map((data) => ({
    label: data?.name,
    backgroundColor: data?.color,
    borderColor: data?.color,
    borderWidth: 1,
    data: data?.data,
    // stack: 'Stack 1',
  }));
  // Check if chart has data
  const hasData = labels?.length > 0 && datasets?.length > 0;

  // const hasData = !multiColumnChartCategories || Object.keys(multiColumnChartCategories).length === 0;
  // datasets?.[0]?.data?.length > 0;

  // Chart data object
  const chartData = {
    labels: labels,
    datasets: datasets,
  };

  // Chart options
  const chartOptions = {
    //  responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
        // borderRadius:"45px"
        labels: {
          usePointStyle: true,
        },
      },
      // title: {
      //   display: true,
      //   font: {
      //     family: 'Urbanist',
      //     weight: 700
      //   },
      //   style: {
      //     color: '#292929',
      //   },
      //   text: "Total Review",
      //   fontSize: 18,
      //   align: "start",
      // },
    },
    scales: {
      x: {
        type: "category",
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
        },
        // title: {
        //   display: true,
        //   text: "Timestamp",
        // },
      },
      y: {
        ticks: {
          beginAtZero: true,
        },
        title: {
          display: true,
          text: "Review Counts",
        },
      },
    },
  };

  // Render the chart or "No data found" message
  return hasData ? (
    <>
      <MDTypography
        sx={{
          marginBottom: "2.3rem",
          color: "#292929",
          fontFamily: "Urbanist",
          fontSize: "18px",
          fontWeight: "700",
          lineHeight: "21.6px",
          "@media(max-width:576px)": { marginBottom: "4rem" },
        }}
      >
        Number of Reviews by Source
      </MDTypography>
      <div style={{ position: "relative", height: "350px", width: "100%" }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
    </>
  ) : (
    "No data found"
  );
};

export default MultiColumnChart;
