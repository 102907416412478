// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import styled from "@emotion/styled";

// Authentication layout components
import PageLayout from "examples/LayoutContainers/PageLayout";

//adorn
import gradientbr from "assets/images/grad-br.png";

//custom components
import Header from "./components/header.js";
import MainSection from "./components/main-section.js";
import Footer from "./components/footer.js";




// import Brands from "./components/brands.js";
import BodySection from "./components/body-section.js";
// import BrandCarousel from "./components/carousel.js";

//styled components
const MainWrapper = styled.div`
  backgroundcolor: #ffffff;
  overflow: hidden;
`;

function Home() {
  return (
    <PageLayout>
      <MainWrapper>
        <MDBox sx={{ position: "relative" }}>
        {/* <MDBox sx={{ position: "absolute", left:"-40%", top:"-45%" }}>
            {" "}
            <MDBox sx={{ width: "803px", height: "803px", borderRadius: "803px", opacity: '0.1', background: "#318BEC", filter: "blur(150px)" }}></MDBox>
          </MDBox> */}
          <MDBox sx={{ position: "absolute", right:"-22%", bottom: "-38%" }}>
            {" "}
            <MDBox sx={{ width: "803px", height: "803px", borderRadius: "803px", opacity: '0.1', background: "#318BEC", filter: "blur(150px)" }}></MDBox>
          </MDBox>

          <MDBox>
            <Header></Header>

            <MainSection></MainSection>
          </MDBox>
        </MDBox>

        {/* <Brands ></Brands> */}
        

        <BodySection></BodySection>

        <Footer></Footer>       
      </MainWrapper>
    </PageLayout>
  );
}

export default Home;
