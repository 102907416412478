import axios from "axios";
import ApiInterceptor from "../ApiInterceptor";
import { config } from "../../../constant";
import { toast } from "react-hot-toast";
// import apiInterceptor from "../ApiInterceptor";

const API_BASE_URL = config?.url?.API_URL;
const GOOGLE_API_URL = config?.url?.GOOGLE_API_URL;
const GOOGLE_API_KEY = config?.url?.GOOGLE_API_KEY;
// Register
export const registerUser = (userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_BASE_URL + "register-user", userData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.status_code == 200) {
          toast.success("Verify your email.");
        }
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response?.data?.statusCode == 400) {
          toast.error(error.response?.data?.message);
        }
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// login
export const loginUser = (userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_BASE_URL + "login", userData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.status_code == 200) {
          const token = response?.data?.data?.access_token;
          localStorage.setItem("token", token);
          toast.success("Login successfully");
        }

        resolve(response?.data);
      })

      .catch((error) => {
        reject(error);
        if (error.response) {
          if (error?.response?.data?.detail?.status_code == 401) {
            toast.error(error?.response?.data?.detail?.message);
            reject(error.response.data);
          }
          reject(error.response.data);
        } else if (error.request) {
          toast.error("Internal server error.");
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// Verify email
export const verifyEmail = (userData) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `verify?email=${userData}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const resendVerifyEmail = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_BASE_URL + `resend-verification-email`, payload, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.status_code == 200) {
          toast.success(response?.data?.message);
        }
        resolve(response.data);
      })

      .catch((error) => {
        toast.error(error?.response?.data?.detail);
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};
// updateProfile
export const updateProfile = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.put(API_BASE_URL + `update-profile`, userData)
      .then((response) => {
        if (response?.data?.status_code == 200) {
          toast.success(response?.data?.message);
        }
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// get user profile
export const getProfile = () => {
  // const config = {
  //   headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  // };
  return new Promise((resolve, reject) => {
    ApiInterceptor.get(API_BASE_URL + `get-profile`, config)
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// deleteUser
export const deleteUser = (userData) => {
  let config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: userData,
  };

  return new Promise((resolve, reject) => {
    ApiInterceptor.delete(API_BASE_URL + "delete_user", config)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Your account has been successfully deleted.");
          setTimeout(() => {
            localStorage.removeItem("token");

            // Clear all cookies
            document.cookie.split(";").forEach((c) => {
              document.cookie = c
                .replace(/^ +/, "")
                .replace(
                  /=.*/,
                  "=;expires=" + new Date().toUTCString() + ";path=/"
                );
            });

            window.location.href = "/sign-in";
          }, 500);
        }
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// forgot-password
export const forgotPassword = (payload) => {
  return new Promise((resolve, reject) => {
    let config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    axios
      .post(API_BASE_URL + `forgot-password`, payload, config)
      .then((response) => {
        if (response.data.statusCode == 200) {
          toast.success(response.data.message);
        } else {
          toast.error(response?.data?.message);
        }
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// reset password
export const resetPassword = ({ payload, config }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_BASE_URL + `reset-password`, payload, config)
      .then((response) => {
        if (response?.data?.statusCode == 200) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
        resolve(response);
      })

      .catch((error) => {
        toast.error(error?.response?.data?.message);
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// update busniess-links
export const updateBusinessPages = (payload) => {
  return new Promise((resolve, reject) => {
    // let config = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   },
    // };
    ApiInterceptor.put(API_BASE_URL + `update-business-pages`, payload)
      .then((response) => {
        if (response?.data?.statusCode == 200) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
        resolve(response);
      })

      .catch((error) => {
        toast.error(error?.response?.data?.message);
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const getAllRestaurants = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `restaurants/`)
      .then((response) => {
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const GoogleSearch = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        GOOGLE_API_URL + `geocode/json?address=${payload}&key=${GOOGLE_API_KEY}`
      )
      .then((response) => {
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const GoogleSearchNearByAddress = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      // .get(
      //   GOOGLE_API_URL +
      //     `place/nearbysearch/json?location=${payload.lat},${payload.long}&radius=50000&type=restaurant&keyword=${payload?.keyword}&key=${GOOGLE_API_KEY}&components=country:US`
      // )
      .get(
        `https://nominatim.openstreetmap.org/search?q=${payload}&format=json`
      )
      .then((response) => {
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const scrapeUrl = (payload) => {
  return new Promise((resolve, reject) => {
    // let config = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   },
    // };
    ApiInterceptor.post(API_BASE_URL + `get_links`, payload)
      .then((response) => {
        if (response?.data?.status_code == 201) {
          toast.success(response?.data?.message);
        }
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const scrapeAll = (payload) => {
  return new Promise((resolve, reject) => {
    // let config = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   },
    // };
    ApiInterceptor.post(API_BASE_URL + `scrapping_reviews`, payload)
      .then((response) => {
        if (response?.data?.status_code == 201) {
          toast.success(response?.data?.message);
        }
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const updateAll = (payload) => {
  return new Promise((resolve, reject) => {
    // let config = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   },
    // };
    ApiInterceptor.post(API_BASE_URL + `update_scrapping_reviews`, payload)
      .then((response) => {
        if (response?.data?.status_code == 201) {
          toast.success(response?.data?.message);
        }
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const addBusiness = (payload) => {
  return new Promise((resolve, reject) => {
    // let config = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   },
    // };
    ApiInterceptor.post(API_BASE_URL + `google_scrape`, payload)
      .then((response) => {
        if (response?.data?.status_code == 201) {
          toast.success(response?.data?.message);
        }
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};
export const addFacebookReviews = (payload) => {
  return new Promise((resolve, reject) => {
    // let config = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   },
    // };
    ApiInterceptor.post(API_BASE_URL + `facebook_scrape`, payload)
      .then((response) => {
        if (response?.data?.status_code == 201) {
          toast.success(response?.data?.message);
        }
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const addYelpReviews = (payload) => {
  return new Promise((resolve, reject) => {
    // let config = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   },
    // };
    ApiInterceptor.post(API_BASE_URL + `yelp_scrape`, payload)
      .then((response) => {
        if (response?.data?.status_code == 201) {
          toast.success(response?.data?.message);
        }
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const addTwitterReviews = (payload) => {
  return new Promise((resolve, reject) => {
    // let config = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   },
    // };
    ApiInterceptor.post(API_BASE_URL + `twitter_scrape`, payload)
      .then((response) => {
        if (response?.data?.status_code == 201) {
          toast.success(response?.data?.message);
        }
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// get all business on the basis of user
export const getMyBusiness = (payload) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(API_BASE_URL + `get-businesses`, payload)
      .then((response) => {
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const getSingleBusiness = (params) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.get(API_BASE_URL + `business/${params}`)
      .then((response) => {
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const editBusiness = (payload) => {
  return new Promise((resolve, reject) => {
    // let config = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   },
    // };
    ApiInterceptor.put(API_BASE_URL + `update-business`, payload)
      .then((response) => {
        if (response?.data?.status_code == 200) {
          toast.success(response?.data?.message);
        }
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// googleToken
export const googleToken = (payload) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(API_BASE_URL + `auth_scrapping_reviews`, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const addUpdatedGoogleReviews = (payload) => {
  return new Promise((resolve, reject) => {
    // let config = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   },
    // };
    ApiInterceptor.post(API_BASE_URL + `twitter_scrape`, payload)
      .then((response) => {
        if (response?.data?.status_code == 201) {
          toast.success(response?.data?.message);
        }
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const addUpdatedfacebookReviews = (payload) => {
  return new Promise((resolve, reject) => {
    // let config = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   },
    // };
    ApiInterceptor.post(API_BASE_URL + `twitter_scrape`, payload)
      .then((response) => {
        if (response?.data?.status_code == 201) {
          toast.success(response?.data?.message);
        }
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const addUpdatedYelpReviews = (payload) => {
  return new Promise((resolve, reject) => {
    // let config = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   },
    // };
    ApiInterceptor.post(API_BASE_URL + `twitter_scrape`, payload)
      .then((response) => {
        if (response?.data?.status_code == 201) {
          toast.success(response?.data?.message);
        }
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const addUpdatedTwitterReviews = (payload) => {
  return new Promise((resolve, reject) => {
    // let config = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   },
    // };
    ApiInterceptor.post(API_BASE_URL + `twitter_scrape`, payload)
      .then((response) => {
        if (response?.data?.status_code == 201) {
          toast.success(response?.data?.message);
        }
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const removeBusiness = (payload) => {
  return new Promise((resolve, reject) => {
    // let config = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   },
    // };
    ApiInterceptor.put(API_BASE_URL + `delete_business`, payload)
      .then((response) => {
        if (response?.data?.status_code == 200) {
          toast.success(response?.data?.message);
        }
        resolve(response);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};
