import { combineReducers } from "@reduxjs/toolkit";
import authSlice  from "../reducers/auth";
import dashboardSlice from "../reducers/dashboard";
import chatSlice from "../reducers/chatbot";

const rootReducer = combineReducers({
  auth: authSlice,
  dashboard: dashboardSlice,
  chat: chatSlice
});

export default rootReducer;
