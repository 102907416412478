import axios from "axios";
import ApiInterceptor from "../ApiInterceptor";
import { config } from "../../../constant";
import { toast } from "react-hot-toast";

const API_BASE_URL = config?.url?.API_URL;

// Register
export const countsRatings = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(API_BASE_URL + "ratings_counts", userData)
      .then((response) => {
        if (response?.data?.status_code == 200) {
          toast.success("Verify your email.");
        }
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const averageRatings = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(API_BASE_URL + "average_rating", userData)
      .then((response) => {
        resolve(response?.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const foodRating = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(API_BASE_URL + "average_food_rating", userData)
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// drinkRating
export const drinkRating = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(API_BASE_URL + "average_drink_rating", userData)
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// serviceRating
export const serviceRating = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(API_BASE_URL + "average_service_rating", userData)
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// sentimentCount
export const sentimentCount = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(API_BASE_URL + "sentiment_count", userData)
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// itemCount
export const itemCount = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(
      API_BASE_URL + "negative_positive_year_top_items",
      userData
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// itemCount
export const negativeItemCount = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(API_BASE_URL + "negativetop_items", userData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// latestReviews
export const latestReviews = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(API_BASE_URL + "latest_reviews", userData)
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// allreviews
export const allreviews = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(API_BASE_URL + "all_reviews", userData)
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// reviewDistribution
export const reviewDistribution = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(API_BASE_URL + "review_count", userData)
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// reviewPerformance
export const reviewPerformance = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(API_BASE_URL + "review_performance", userData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// processInstruction
export const processInstruction = (userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_BASE_URL + "process_answer", userData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// processInstruction
export const markAsRead = (userData) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        API_BASE_URL + `update_review?common_id=${userData?.common_id}`,
        { ids: userData?.ids },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// singleReview
export const singleReview = (userData) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `single_review/${userData?.review_id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

// singleReview
export const replayReviews = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(API_BASE_URL + `reply_to_review`, userData)
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const deleteCaches = (userData) => {
  return new Promise((resolve, reject) => {
    axios.post(API_BASE_URL + `delete_caches`, userData)
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const scrapeStatus = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(API_BASE_URL + `scrape_status`, userData)
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const topReview = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(
      API_BASE_URL + "year_top_reviews_items_reviews",
      userData
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const topNegativeReview = (userData) => {
  return new Promise((resolve, reject) => {
    ApiInterceptor.post(
      API_BASE_URL + "year_topnegative_reviews_items_reviews",
      userData
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};

export const autoGenerateReply = (userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_BASE_URL + "chatbot/reply_response", userData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject("No response received");
        } else {
          reject(error.message);
        }
      });
  });
};
