import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';


const Protected = ({children}) => {
    const navigate = useNavigate();
    useEffect(()=>{
      const Auth = localStorage.getItem('token')
        if(!Auth){
            return navigate('/')
        }
    },[]);

return children;

}

export default Protected