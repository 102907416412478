import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "chartjs-plugin-datalabels"; // Import chartjs-plugin-datalabels
import MDBox from "components/MDBox";
import Modal from "@mui/material/Modal";

import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { topReviewAsync } from "features/actions/dashboard";
import { singleReviewAsync } from "features/actions/dashboard";
import { clearModalState } from "features/reducers/dashboard";
import boxShadow from "assets/theme/functions/boxShadow";
import { serviceAsync } from "features/reducers/dashboard";
import { positiveItemName } from "features/reducers/dashboard";
import { sourceAbbr } from "examples/commonFolder/commonfolder";
import toast from "react-hot-toast";

const DoughnutChart = ({
  DoughnutChart,
  colorArr,
  setLoading,
  source,
  positiveSentiment,
}) => {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);
  const [modalOpenPositive, setModalOpenPositive] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const { topReview, positiveItemLabel, service, itemCount } = useSelector(
    (state) => state.dashboard
  );
  const [allExpandedRows, setAllExpandedRows] = useState([]);
  const [showItem, setShowItem] = useState(null);
  const [showData, setShowData] = useState(false);

  const [topReviewData, setTopReviewData] = useState();
  const businessId = localStorage.getItem("common_id");
  const [showSentimentType, setShowSentimentType] = useState("");
  const sourceLabel = sourceAbbr[source] || "";

  const [item, setItem] = useState("");
  const AlltoggleRowExpansion = (index) => {
    setAllExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(index)) {
        return prevExpandedRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevExpandedRows, index];
      }
    });
  };

  const handleRowClick = async (row) => {
    try {
      setLoading(true);
      await dispatch(singleReviewAsync({ review_id: row?.id }));
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleChartClick = (event, elements) => {
    if (elements.length) {
      const clickedIndex = elements?.[0]?.index;
      const clickedItem = Object.keys(DoughnutChart)[clickedIndex];

      setSelectedItem(clickedItem);
      setShowItem(clickedItem);
    }
  };

  const chartDataArray = Object.entries(DoughnutChart || {}).map(
    ([name, value], index) => ({
      name,
      y: value,
      color: colorArr[index % colorArr.length],
    })
  );

  const hasData = !DoughnutChart || Object.keys(DoughnutChart).length === 0;

  const chartData = hasData
    ? {
        labels: ["no Data"],
        datasets: [
          {
            data: [100],
            backgroundColor: ["rgba(192, 192, 192, 0.6)"],
            label: "Count:",
          },
        ],
      }
    : {
        labels: chartDataArray?.map((point) => point.name),
        datasets: [
          {
            data: chartDataArray?.map((point) => point.y),
            backgroundColor: chartDataArray.map((point) => point.color),
            label: "Count:",
          },
        ],
      };

  const options = {
    // responsive: true,
    onClick: (e, elements) => {
      handleChartClick(e, elements);
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataset = context.dataset;
            const currentValue = dataset.data[context.dataIndex];
            const countValue = hasData ? 0 : currentValue;
            return dataset.label + " " + countValue;
          },
        },
      },
    },
  };

  useEffect(() => {
    if (itemCount) {
      setModalOpenPositive(false);
    } else {
      dispatch(clearModalState());
      dispatch(serviceAsync({ topTrend: "", year: "" }));
      setModalOpenPositive(false);
    }
  }, [itemCount]);

  useEffect(() => {
    if (positiveItemLabel) {
      setSelectedItem(positiveItemLabel);
      setShowItem(positiveItemLabel);
    }
  }, [positiveItemLabel]);

  useEffect(() => {
    setShowData(false);

    if (selectedItem) {
      setLoading(true);
      dispatch(
        topReviewAsync({
          id: businessId,
          service_type: service?.topTrend || "food",
          sentiment: positiveSentiment,
          top_item_category: selectedItem,
          year: service?.year,
          source: sourceLabel,
        }),
        setShowData(false)
      )?.finally(() => setLoading(false));
    }
  }, [selectedItem]);
  useEffect(() => {
    if (topReview?.reviews?.length > 0 && showData) {
      if (showSentimentType === "positive") {
        setTopReviewData(topReview?.reviews);
        setModalOpenPositive(true);
      }
      setSelectedItem(null);
      dispatch(clearModalState());
    } else if (
      (topReview?.reviews?.length == 0 && showData) ||
      topReview?.detail
    ) {
      if (showSentimentType === "positive") {
        // setTopReviewData(topReview?.reviews);
        // setModalOpenPositive(true);
        toast.error("No reviews found.");
      }
      setSelectedItem(null);
      dispatch(clearModalState());
      setShowSentimentType("");
      dispatch(positiveItemName(""));
    }
  }, [showData, showSentimentType]);

  useEffect(() => {
    if (topReview) {
      callApi();
    }
  }, [topReview]);

  const callApi = async () => {
    await setShowSentimentType(positiveSentiment);
    await setItem(showItem);
    setShowData(true);
  };

  const positiveModal = () => {
    setModalOpenPositive(false);
    setShowSentimentType("");
    dispatch(positiveItemName(""));
  };

  return (
    <div>
      <span
        style={{
          color: "#43B077",
          fontFamily: "urbanist",
          fontSize: "18px",
          fontWeight: "700",
          lineHeight: "21.6px",
          textTransform: "capitalize",
        }}
      >
        {positiveSentiment}
      </span>
      <div
        style={{
          width: "190px",
          margin: "20px auto",
          "@media(max-width:992px)": { margin: "0px auto" },
        }}
      >
        <Doughnut options={options} data={chartData} />
      </div>
      {modalOpenPositive && showSentimentType == "positive" && (
        <MDBox sx={{ borderRadius: "24px", overflow: "hidden" }}>
          <Box
            sx={{
              position: "absolute",
              top: "0%",
              left: "50%",
              width: "auto",
              bgcolor: "background.paper",
              height: "400px",
              boxShadow: 8,
              borderRadius: "24px",
              p: 4,
              zIndex: 1,
              "@media(max-width:992px)": {
                left: "0",
                top: "98%",
                width: "auto",
              },
            }}
          >
            <MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    textTransform: "capitalize",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    // cursor: "pointer",
                    fontWeight: 700,
                    fontFamily: "urbanist",
                    color: "#318BEC",
                    textTransform: "capitalize",
                    fontSize: "16px",
                  }}
                >
                  {showSentimentType}/{item}
                </p>
                <MDBox
                  sx={{
                    borderRadius: "8px",
                    height: "30px",
                    px: 1,
                    pb: 1.3,
                    pt: 0.6,
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#00000010",
                    },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={positiveModal}
                >
                  <p style={{ fontWeight: 600, fontSize: "24px" }}>x</p>
                </MDBox>
              </MDBox>
              <MDBox
                className="customScrollbar"
                sx={{ overflowY: "scroll", height: "280px", pr: 1 }}
              >
                {topReviewData?.length > 0 &&
                  topReviewData?.slice(0, 10)?.map((ele, ind) => {
                    return (
                      <p
                        style={{
                          borderBottom: "1px solid #838383",
                          paddingBottom: "10px",
                          paddingTop: "10px",
                          cursor: "pointer",
                          fontFamily: "urbanist",
                          fontSize: "16px",
                          lineHeight: "21px",
                        }}
                        onClick={() => handleRowClick(ele)}
                      >
                        {allExpandedRows?.includes(ind) || !ele?.review ? (
                          "No Reviews"
                        ) : ele.review.length <= 100 ? (
                          <>
                            {ele.review}
                            {ele.review.length > 100 && (
                              <span style={{ color: "#318BEC" }}>
                                ...Show less
                              </span>
                            )}
                          </>
                        ) : (
                          <>
                            {ele.review.slice(0, 100)}...
                            <span style={{ color: "#318BEC" }}>Read more</span>
                          </>
                        )}
                      </p>
                    );
                  })}
              </MDBox>
            </MDBox>
          </Box>
        </MDBox>
      )}
    </div>
  );
};

export default DoughnutChart;
