import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import MDBox from "components/MDBox";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  topNegativeReviewAsync,
  singleReviewAsync,
} from "features/actions/dashboard";
import {
  serviceAsync,
  clearModalState,
  clearModalState1,
} from "features/reducers/dashboard";
import { itemName } from "features/reducers/dashboard";
import { sourceAbbr } from "examples/commonFolder/commonfolder";
import toast from "react-hot-toast";

const DoughnutChart = ({
  DoughnutChart,
  colorArr,
  setLoading,
  source,
  negativeSentiment,
}) => {
  const dispatch = useDispatch();
  const [modalOpenNegative, setModalOpenNegative] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { topReviewNeg, service, itemLabel, itemCount } = useSelector(
    (state) => state.dashboard
  );
  const [allExpandedRows, setAllExpandedRows] = useState([]);
  const [showItem1, setShowItem1] = useState(null);
  const [showData1, setShowData1] = useState(false);

  const [topReviewData, setTopReviewData] = useState();
  const businessId = localStorage.getItem("common_id");
  const [showSentimentType1, setShowSentimentType1] = useState("");
  const [item, setItem] = useState("");
  const sourceLabel = sourceAbbr[source] || "";

  const AlltoggleRowExpansion = (index) => {
    setAllExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(index)) {
        return prevExpandedRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevExpandedRows, index];
      }
    });
  };
  const handleRowClick = async (row) => {
    try {
      setLoading(true);
      await dispatch(singleReviewAsync({ review_id: row?.id }));
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleChartClick = (event, elements) => {
    if (elements.length) {
      const clickedIndex = elements?.[0]?.index;
      const clickedItem = Object.keys(DoughnutChart)[clickedIndex];
      setSelectedItem(clickedItem);
      setShowItem1(clickedItem);
    }
  };

  const chartDataArray = Object.entries(DoughnutChart || {}).map(
    ([name, value], index) => ({
      name,
      y: value,
      color: colorArr[index % colorArr.length],
    })
  );

  const hasData = !DoughnutChart || Object.keys(DoughnutChart).length === 0;

  const chartData = hasData
    ? {
        labels: ["no Data"],
        datasets: [
          {
            data: [100],
            backgroundColor: ["rgba(192, 192, 192, 0.6)"],
            label: "Count:",
          },
        ],
      }
    : {
        labels: chartDataArray?.map((point) => point.name),
        datasets: [
          {
            data: chartDataArray?.map((point) => point.y),
            backgroundColor: chartDataArray.map((point) => point.color),
            label: "Count:",
          },
        ],
      };
  const options = {
    onClick: (e, elements) => {
      handleChartClick(e, elements);
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataset = context.dataset;
            const currentValue = dataset.data[context.dataIndex];
            const countValue = hasData ? 0 : currentValue;
            return dataset.label + " " + countValue;
          },
        },
      },
    },
  };

  useEffect(() => {
    if (itemCount) {
      setModalOpenNegative(false);
    }
  }, [itemCount]);

  useEffect(() => {
    if (itemLabel) {
      setSelectedItem(itemLabel);
      setShowItem1(itemLabel);
    }
  }, [itemLabel]);

  useEffect(() => {
    dispatch(clearModalState1());
    setModalOpenNegative(false);
    dispatch(serviceAsync({ topTrend: "", year: "" }));
  }, []);

  useEffect(() => {
    setShowData1(false);

    if (selectedItem) {
      setLoading(true);
      dispatch(
        topNegativeReviewAsync({
          id: businessId,
          service_type: service?.topTrend || "food",
          sentiment: negativeSentiment,
          top_item_category: selectedItem,
          year: service?.year,
          source: sourceLabel,
        }),
        setShowData1(false)
      )?.finally(() => setLoading(false));
    }
  }, [selectedItem]);

  useEffect(() => {
    if (topReviewNeg?.reviews?.length > 0 && showData1) {
      if (showSentimentType1 === "negative") {
        setTopReviewData(topReviewNeg?.reviews);
        setModalOpenNegative(true);
      }
      setSelectedItem(null);
      dispatch(clearModalState());
    } else if (
      (topReviewNeg?.reviews?.length == 0 && showData1) ||
      topReviewNeg?.detail
    ) {
      if (showSentimentType1 === "negative") {
        toast.error("No reviews found.");
      }
      setSelectedItem(null);
      dispatch(clearModalState());
      setShowSentimentType1("");
      dispatch(itemName(""));
    }
  }, [showData1, showSentimentType1]);


  useEffect(() => {
    if (topReviewNeg) {
      callApi();
    }
  }, [topReviewNeg]);

  const callApi = async () => {
    await setShowSentimentType1(negativeSentiment);
    await setItem(showItem1);
    setShowData1(true);
  };

  const negativeModal = () => {
    setModalOpenNegative(false);
    setShowSentimentType1("");
    dispatch(itemName(""));
  };
  return (
    <div>
      {/* <h6
        style={{
          marginBottom: "2rem",
          color: "#292929",
          fontFamily: "Urbanist",
          fontSize: "18px",
          fontWeight: "700",
          lineHeight: "21.6px",
        }}
      >
        Most Trending Items{" "} */}
      <span
        style={{
          color: "#FF5454",
          fontSize: "18px",
          fontWeight: "700",
          lineHeight: "21.6px",
          fontFamily: "urbanist",
          textTransform: "capitalize",
        }}
      >
        {negativeSentiment}
      </span>
      {/* </h6> */}
      <div
        style={{
          width: "190px",
          margin: "20px auto",
          "@media(max-width:992px)": { margin: "0px auto" },
        }}
      >
        <Doughnut options={options} data={chartData} />
      </div>

      {modalOpenNegative && showSentimentType1 == "negative" && (
        <MDBox sx={{ borderRadius: "24px", overflow: "hidden" }}>
          <Box
            sx={{
              position: "absolute",
              top: "0%",
              left: "50%",
              // transform: "translate(-50%, -50%)",
              width: "auto",
              bgcolor: "background.paper",
              height: "400px",
              boxShadow: 8,
              borderRadius: "24px",
              p: 4,
              zIndex: 1,
              "@media(max-width:992px)": {
                left: "0",
                top: "98%",
                width: "auto",
              },
            }}
          >
            <MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p
                  className="capitalize-text"
                  style={{
                    textTransform: "capitalize",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    fontWeight: 700,
                    fontFamily: "urbanist",
                    color: "#318BEC",
                    textTransform: "capitalize",
                    fontSize: "16px",
                  }}
                >
                  {showSentimentType1}/{item}{" "}
                </p>
                <MDBox
                  sx={{
                    borderRadius: "8px",
                    height: "30px",
                    px: 1,
                    pb: 1.3,
                    pt: 0.6,
                    cursor: "pointer",

                    "&:hover": {
                      backgroundColor: "#00000010",
                    },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={negativeModal}
                >
                  <p style={{ fontWeight: 600, fontSize: "24px" }}>x</p>
                </MDBox>
              </MDBox>
              <MDBox
                className="customScrollbar"
                sx={{ overflowY: "scroll", height: "280px", pr: 1 }}
              >
                {topReviewData?.length > 0 &&
                  topReviewData?.slice(0, 10)?.map((ele, ind) => {
                    return (
                      <p
                        style={{
                          borderBottom: "1px solid #838383",
                          paddingBottom: "10px",
                          paddingTop: "10px",
                          cursor: "pointer",
                          fontFamily: "urbanist",
                          fontSize: "16px",
                          lineHeight: "21px",
                        }}
                        onClick={() => handleRowClick(ele)}
                      >
                        {allExpandedRows?.includes(ind) || !ele?.review ? (
                          "No Reviews"
                        ) : ele.review.length <= 100 ? (
                          <>
                            {ele.review}
                            {ele.review.length > 100 && (
                              <span style={{ color: "#318BEC" }}>
                                ...Show less
                              </span>
                            )}
                          </>
                        ) : (
                          <>
                            {ele.review.slice(0, 100)}...
                            <span style={{ color: "#318BEC" }}>Read more</span>
                          </>
                        )}
                      </p>
                    );
                  })}
              </MDBox>
            </MDBox>
          </Box>
        </MDBox>
      )}
    </div>
  );
};

export default DoughnutChart;
