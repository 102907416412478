import notStartedIcon from "../../assets/images/icons/notStarted.svg";
import inProgressIcon from "../../assets/images/icons/inProgress.svg";
import failedIcon from "../../assets/images/icons/failed.svg";
import completedIcon from "../../assets/images/icons/completed.svg";
import sentimentInProgressIcon from "../../assets/images/icons/sentiment.svg";
import embeddingInProgressIcon from "../../assets/images/icons/embedding.svg";

export const statusIcons = {
  "Not started": notStartedIcon,
  "In-progress": inProgressIcon,
  "Failed": failedIcon,
  "Completed": completedIcon,
  "Creating sentiments": sentimentInProgressIcon,
  "Creating Embeddings": embeddingInProgressIcon,
};

export const attentionArr = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];

export const sourceArr = [
  {
    value: "g",
    label: "Google",
  },
  {
    value: "y",
    label: "Yelp",
  },
  {
    value: "f",
    label: "Facebook",
  },
  {
    value: "t",
    label: "X",
  },
];

export const sourceLabels = {
  g: "Google",
  y: "Yelp",
  f: "Facebook",
  t: "X",
};

export const sourceAbbr = {
  google: "g",
  facebook: "f",
  yelp: "y",
  x: "t",
  dashboard: "",
};

export const doughnutFilterProps = {
  google: "g",
  facebook: "f",
  yelp: "y",
  x: "t",
  dashboard: "",
};

export const sourceName = {
  google: "google",
  facebook: "facebook",
  yelp: "yelp",
  x: "x",
  dashboard: "",
};

export const syncingStatus = {
  NOT_STARTED: "Not started",
  IN_PROGRESS: "In-progress",
  FAILED: "Failed",
  COMPLETED: "Completed",
  SENTIMENT_IN_PROGRESS: "Creating sentiments",
  EMBEDDING_IN_PROGRESS: "Creating Embeddings",
};

export const statusColors = {
  NOT_STARTED: "Grey", // Black
  IN_PROGRESS: "blue",
  FAILED: "red",
  COMPLETED: "green",
  SENTIMENT_IN_PROGRESS: "orange",
  EMBEDDING_IN_PROGRESS: "purple",
};

export const statusBackgroundColor = {
  NOT_STARTED: "#8080801A",
  IN_PROGRESS: "#0000FF1A",
  FAILED: "#FF54541A",
  COMPLETED: "#43B0771A",
  SENTIMENT_IN_PROGRESS: "#FFA5001A",
  EMBEDDING_IN_PROGRESS: "#A020F01A",
};

export const dummyArr = [0, 0, 0, 0, 0, 0, 0, 0, 0];

export const dummyYearArr = [
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
];

export const ratingArr = [
  {
    value: "0",
    label: "0",
  },
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
];

export const replyArr = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

export const chartColor = [
  "#ffee99",
  "#f7b2c1",
  "#75a3e0",
  "#9ADFFF",
  "#CCEFFF",
  "#1814F3",
  "#318BEC",
  "#4351EC",
  "#318BEC",
  "#43B077",
  "#FF5454",
  "#43B077",
  "#F2D322",
  "#FFD337",
  "#BDE3CF",
  "#0094E9",
  "#DE6E5C",
  "#49C47C",
  "#78d69b",
  "#318BEC",
  "#227BA5",
  "#9ADFFF",
  "#a3a0f6",
];

export const MonthsArr = [
  {
    value: "1",
    label: "January",
  },
  {
    value: "2",
    label: "February",
  },
  {
    value: "3",
    label: "March",
  },
  {
    value: "4",
    label: "April",
  },
  {
    value: "5",
    label: "May",
  },
  {
    value: "6",
    label: "June",
  },
  {
    value: "7",
    label: "July",
  },
  {
    value: "8",
    label: "August",
  },
  {
    value: "9",
    label: "September",
  },
  {
    value: "10",
    label: "October",
  },
  {
    value: "11",
    label: "November",
  },
  {
    value: "12",
    label: "December",
  },
];

export const sentimentArr = [
  {
    value: "positive",
    label: "Positive",
  },
  {
    value: "negative",
    label: "Negative",
  },
];

export const itemsArr = [
  {
    value: "food",
    label: "Food",
  },
  {
    value: "drink",
    label: "Drink",
  },
  {
    value: "staff",
    label: "Staff",
  },
];

export const yearArr = [
  {
    value: "2007",
    label: "2007",
  },
  {
    value: "2008",
    label: "2008",
  },
  {
    value: "2009",
    label: "2009",
  },
  {
    value: "2010",
    label: "2010",
  },
  {
    value: "2011",
    label: "2011",
  },
  {
    value: "2012",
    label: "2012",
  },
  {
    value: "2013",
    label: "2013",
  },
  {
    value: "2014",
    label: "2014",
  },
  {
    value: "2015",
    label: "2015",
  },
  {
    value: "2016",
    label: "2016",
  },
  {
    value: "2017",
    label: "2017",
  },
  {
    value: "2018",
    label: "2018",
  },
  {
    value: "2019",
    label: "2019",
  },
  {
    value: "2020",
    label: "2020",
  },
  {
    value: "2021",
    label: "2021",
  },
  {
    value: "2022",
    label: "2022",
  },
  {
    value: "2023",
    label: "2023",
  },
  {
    value: "2024",
    label: "2024",
  },
];
