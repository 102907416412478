import { useState, useEffect, useRef, useLayoutEffect } from "react";
// import './global.css'
// react-router components
import { useLocation, Link, navigate, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import IconButton from "@mui/material/IconButton";
import { Modal, Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import NavbarSelect from "./select-menu";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import TextField from "@mui/material/TextField";
import bellIcon from "assets/images/bell-icon.svg";
import avatarIcon from "assets/images/avatar-icon.svg";
import searchInput from "assets/images/search-input.svg";
import buttonSparkles from "assets/images/button-sparkles.svg";
import recentIcon from "assets/images/small-logos/recent-icon.svg";
import burgerIcon from "assets/images/small-logos/new-burger-icon.svg";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

import ProfileMenu from "../../../layouts/dashboard/profile-menu";
import { config } from "constant";
// Material Dashboard 2 React context
import { setTransparentNavbar } from "context";
import { Paper } from "@mui/material";
import MDTypography from "components/MDTypography";
import { askQuestionAsync } from "features/actions/chat";
import { useDispatch, useSelector } from "react-redux";
import { askQuestionState } from "features/reducers/chatbot";
import { getProfileAysnc } from "features/actions/auth";

import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

let questionStatic = [
  {
    question: "What is the latest review for my restaurant?",
  },
  {
    question: "Reviews that needs attention",
  },
  {
    question: "What are the top 5 best-selling drinks?",
  },
  {
    question: "Top 5 food items for my restaurant?",
  },
];

const PROFILEURI = config?.url?.PROFILEURI;

function DashboardNavbar({ absolute, light, isMini }) {
  const [isFocused, setIsFocused] = useState(true);
  const inputRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const handleInputFocus = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const dispatchData = useDispatch();
  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const [askQuestion, setAskQuestion] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  let { getprofile } = useSelector((state) => state.auth);
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const businessId = localStorage.getItem("common_id");

  const route = useLocation().pathname.split("/").slice(1);
  const url = window.location.href;
  const segments = url.split("/"); // Split the URL by "/"
  const lastSegment = segments[segments?.length - 1];
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    //   // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    //   /**
    //    The event listener that's calling the handleTransparentNavbar function when
    //    scrolling the window.
    //   */
    window.addEventListener("scroll", handleTransparentNavbar);

    //   // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    //   // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);



  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem
        icon={<Icon>podcasts</Icon>}
        title="Manage Podcast sessions"
      />
      <NotificationItem
        icon={<Icon>shopping_cart</Icon>}
        title="Payment successfully completed"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  useEffect(() => {
    if (askQuestion) {
      navigate("/chatbot");
      setAskQuestion(false);
    }
  }, [askQuestion]);
  useEffect(() => {
    dispatchData(getProfileAysnc());
  }, [askQuestion]);

  const handleAskQuestion = (question) => {
    dispatchData(askQuestionState(question));
    setAskQuestion(true);
  };

  const sendData = () => {
    dispatchData(askQuestionState(inputMessage));
    setAskQuestion(true);
  };

  const handleMessage = (e) => {
    setInputMessage(e.target.value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useLayoutEffect(() => {
    const hasBusinesses = getprofile?.businesses?.length !== 0;
    const isNotExcludedPage =
      lastSegment !== "my-business" &&
      lastSegment !== "view-business" &&
      lastSegment !== "profile" &&
      lastSegment !== "business-detail";

    // Check if the special condition for showing the Navbar is met
    const shouldShowNavbar =
      hasBusinesses &&
      isNotExcludedPage &&
      // getprofile?.businesses?.length > 0 &&
      getprofile?.user_business_status?.[0]?.true_count != 0;
    // (hasBusinesses &&
    //   isNotExcludedPage &&
    //   getprofile?.businesses?.length > 1);
    setShowNavbar(shouldShowNavbar);
  }, [getprofile, lastSegment]);

  return (
    <AppBar
      position={absolute ? "absolute" : "static"}
      color="inherit"
      sx={[
        (theme) =>
          navbar(theme, { transparentNavbar, absolute, light, darkMode }),
        {
          "& .MuiToolbar-root": { flexDirection: { xs: "column", lg: "row" } },
          "@media(max-width:991px)": {
            "& .MuiToolbar-root": {
              justifyContent: "start",
              alignItems: "start",
            },
          },
        },
      ]}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        {lastSegment !== "scrape-page" && (
          <MDBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            <Breadcrumbs
              icon="home"
              title={route[route.length - 1]}
              route={route}
              light={light}
            />
          </MDBox>
        )}
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}></MDBox>
        )}
        <MDBox
          sx={{
            display: "flex",
            '@media(max-width:"1000px")': {
              display: "flex",
              width: "100%",
              alignItems: "start",
              justifyContent: "start",
              textAlign: "left",
            },
          }}
        >
          <MDBox sx={{ padding: 0, width: "100%" }}>
            {showNavbar && (
              <MDBox sx={{ padding: 0 }}>
                <NavbarSelect />
              </MDBox>
            )}
          </MDBox>
        </MDBox>

        <MDBox
          sx={{
            display: "flex",
            alignItems: "end",
            "@media(max-width: 528px)": {
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
              gap: "20px",
            },
            flexDirection: "row",
          }}
        >
          {showNavbar &&
            businessId !== "0" &&
            lastSegment !== "scrape-page" && (
              <MDBox
                component={Link}
                sx={{ mr: 0, "@media(min-width:500px)": { mr: 3 } }}
              >
                <div className={openModal ? "focused card" : " card"}>
                  <MDBox sx={{ position: "relative", zIndex: 999 }}>
                    <MDBox className="dummyInput">
                      <MDBox
                        sx={{ position: "absolute", left: "4px", top: "4.8px" }}
                      >
                        <MDBox
                          sx={{
                            backgroundColor: "#318BEC",
                            borderRadius: "50%",
                            width: "35px",
                            height: "35px",
                          }}
                        >
                          <img
                            src={buttonSparkles}
                            style={{ marginTop: "6px", marginLeft: "3px" }}
                            alt="icon"
                          />
                        </MDBox>
                      </MDBox>

                      <input
                        onClick={handleInputFocus}
                        className="inputFocus"
                        style={{
                          borderRadius: "30px",
                          height: "45px",
                          width: "100%",
                          "@media(max-width:420px)": {
                            maxWidth: "80%",
                          },
                          margin: 0,
                          border: "0",
                          outline: "none",
                          background: "#FFFFFF",
                          padding: "20px 10px",
                          paddingLeft: "50px",
                          "&:focus": {
                            outline: "none",
                            border: "0",
                          },
                        }}
                        value={inputMessage}
                        onChange={(e) => handleMessage(e)}
                        type="text"
                        placeholder="Ask me anything about your reviews...."
                        onKeyDown={(event) =>
                          event.key === "Enter" ? sendData() : null
                        }
                      />
                    </MDBox>

                    <Modal
                      open={openModal}
                      onClose={handleCloseModal}
                      // aria-labelledby="modal-title"
                      // aria-describedby="modal-description"Modal
                      className="modalCustom"
                      disableAutoFocus
                    >
                      <div className="modalInner" tabIndex={0}>
                        <MDBox
                          sx={{
                            position: "absolute",
                            top: "-60px",
                            right: "0px",
                            left: "auto",
                            zIndex: "5",
                            "@media(max-width:991px)": {
                              left: "0px",
                              right: "auto",
                              top: "-60.5px",
                            },
                            // '@media(max-width:991px)':{left:"0px",right:"auto",top:"-57.5px"}
                          }}
                        ></MDBox>

                        <div className="upper">
                          <div className="title">Search Recommendations</div>
                          <div
                            className="close hover-shadow"
                            onClick={handleCloseModal}
                          >
                            X
                          </div>
                        </div>
                        <Grid container className="items">
                          {questionStatic?.map((ele, index) => {
                            return (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  className="item"
                                  key={index}
                                  onClick={() =>
                                    handleAskQuestion(ele?.question)
                                  }
                                >
                                  {ele.question}
                                </Grid>
                              </>
                            );
                          })}
                        </Grid>
                      </div>
                    </Modal>
                  </MDBox>
                </div>
              </MDBox>
            )}

          <Paper
            elevation={0}
            sx={{
              borderRadius: "100px",
              padding: "3px",
              position: "relative",
              zIndex: "1",
              "@media(max-width:576px)": {
                position: "absolute",
                right: "16px",
                top: "5px",
              },
            }}
          >
            <MDBox
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: 0.5,
              }}
            >
              {/* <MDBox sx={{ position: "relative" }}><MDBox sx={{ position: "absolute", left: "10px", top: "4px" }}><img src={searchInput} alt="icon"  /></MDBox><input style={{ borderRadius: "26px", height: "35px", margin: 0, border: 0, background: "#F4F7FE", padding: "20px 10px", paddingLeft: "40px" }} type="text" placeholder="Search" /></MDBox> */}
              {/* <MDBox sx={{ px: 1, mt: 1 }} component={Link}>
                <img src={bellIcon} alt="icon" />
              </MDBox> */}
              {/* <MDBox component={Link} sx={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={avatarIcon} alt="icon" /></MDBox> */}
              <ProfileMenu />
              <button className="burgerMenu" onClick={handleMiniSidenav}>
                <img src={burgerIcon} alt="menu icon" />
              </button>
            </MDBox>
          </Paper>
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
