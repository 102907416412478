// CombinedChart.js
import { Face } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";
import { Line, Bar } from "react-chartjs-2";
import MDTypography from "components/MDTypography";


const CombinedChart = ({ data }) => {
  if (!data || Object.keys(data)?.length === 0) {
    data = {
      Timestamp: { average_score: 0, total_reviews: 0 },
    };
  }

  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        type: "line",
        label: "Average Score",
        data: Object.values(data)?.map((year) => year?.average_score),
        borderColor: "#4e7af2",
        backgroundColor: "#052fa3",
        yAxisID: "y",
      },
      {
        type: "bar",
        label: "Total Reviews",
        data: Object.values(data)?.map((year) => year?.total_reviews),
        backgroundColor: "#a7cffa50",
        borderColor: "#318BEC",
        borderWidth: 1,
        yAxisID: "y1",
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: "category",
        display: true,
        // title: {
        //   display: true,
        //   text: "Timestamp",
        // },
      },
      y: {
        type: "linear",
        display: true,
        position: "left", // Left position for y-axis-1
        // id: 'y',
        labelString: "Average Score",
        title: {
          display: true,
          text: "Average Ratings",
        },

        ticks: {
          beginAtZero: true,
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right", // Right position for y-axis-2I
        // id: 'y1',
        title: {
          display: true,
          text: "Total Reviews",
        },
        labelString: "Total Reviews",
        ticks: {
          beginAtZero: true,
        },
      },
    },

  };


  return (
    <div>
      <MDTypography sx={{'@media(max-width:576px)':{marginBottom:"4rem"},marginBottom:"2.3rem",color:"#292929",fontFamily: "Urbanist",fontSize:"18px",fontWeight:"700",lineHeight:"21.6px"}}>Review Performance</MDTypography>
      <Line data={chartData} options={options} height={150}    />
    </div>
  );
};

export default CombinedChart;
