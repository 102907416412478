// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayoutCustom from "examples/LayoutContainers/DashboardLayoutCustom";

// Overview page components
import MDInput from "components/MDInput";
import burceMars from "assets/images/dummy.png";
import backgroundImage from "assets/images/bg-profile.jpg";
import avatarEditIcon from "assets/images/avatar-edit-icon.svg";
import profileEditIcon from "assets/images/small-logos/profile-edit-icon.svg";
import { Button, Input, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import {
  deleteAccountAsync,
  getProfileAysnc,
  updateProfileAsync,
} from "../../features/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import ReactInputMask from "react-input-mask";
import { clearUpdateProfile } from "features/reducers/auth";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import { config } from "constant";
import Loader from "assets/loader/loader";
import userdelete from "assets/images/deleteIcon.svg";
const PROFILEURI = config?.url?.PROFILEURI;
function Overview() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "24px",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    py: 5,
  };

  const dispatch = useDispatch();
  const { getprofile, updateProfile, authLoading } = useSelector(
    (state) => state.auth
  );
  const [editState, setEditState] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastName, setlastName] = useState("");
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [previewImage, setPreviewImage] = useState();
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
  });

  useEffect(() => {
    dispatch(getProfileAysnc());
  }, []);

  useEffect(() => {
    if (getprofile?.userProfile) {
      let newValue = {};
      Object.keys(getprofile?.userProfile).map((keyName, i) => {
        if (getprofile?.userProfile[keyName] !== null) {
          newValue = {
            ...newValue,
            [keyName]: getprofile?.userProfile[keyName],
          };
          if (keyName === "firstName") {
            setFirstname(getprofile?.userProfile[keyName]);
          }
          if (keyName === "lastName") {
            setlastName(getprofile?.userProfile[keyName]);
          }
        }
      });
      setValues({ ...values, ...newValue });
    }
  }, [getprofile]);

  useEffect(() => {
    if (updateProfile?.status_code == 200) {
      setEditState(false);
      dispatch(getProfileAysnc());
      dispatch(clearUpdateProfile());
    }
  }, [updateProfile]);
  const handleRemoveMaskingChars = (phoneNumber) => {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, ""); // Remove non-numeric characters
    return cleanedPhoneNumber;
  };
  const validateForm = () => {
    const newErrors = {};
    const checkName = /^[a-zA-Z\s]+$/;
    const checkAddress = /^[a-zA-Z0-9\s.,#-]+$/;
    const checkPhoneNumber = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const checkZipCode = /^\d{4,6}$/;
    // const checkEmail = /\S+@\S+\.\S+/;

    // Validate email
    // if (!values.email) {
    //   newErrors.email = "Email is required";
    // } else if (!checkEmail.test(values.email)) {
    //   newErrors.email = "Invalid email format";
    // }

    // Validate name
    if (!values.firstName) {
      newErrors.firstName = "First name is required";
    } else if (!checkName.test(values.firstName)) {
      newErrors.firstName = "Invalid format";
    }

    if (!values.lastName) {
      newErrors.lastName = "last name is required";
    } else if (!checkName.test(values.lastName)) {
      newErrors.lastName = "Invalid format";
    }

    // Validate phone number
    if (!values.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required.";
    }
    if (
      values.phoneNumber &&
      !checkPhoneNumber.test(handleRemoveMaskingChars(values.phoneNumber))
    ) {
      newErrors.phoneNumber =
        "Please enter a numeric phone number with at least 10 digits.";
    }

    if (!values.address) {
      newErrors.address = "Address is required";
    } else if (!checkAddress.test(values.address)) {
      newErrors.address = "Invalid format";
    }
    if (!values.country) {
      newErrors.country = "Country is required";
    } else if (!checkName.test(values.country)) {
      newErrors.country = "Invalid format";
    }
    if (!values.state) {
      newErrors.state = "State is required";
    } else if (!checkName.test(values.state)) {
      newErrors.state = "Invalid format";
    }
    if (!values.city) {
      newErrors.city = "City is required";
    } else if (!checkName.test(values.city)) {
      newErrors.city = "Invalid format";
    }
    if (!values.zipcode) {
      newErrors.zipcode = "ZipCode is required";
    } else if (!checkZipCode.test(values.zipcode)) {
      newErrors.zipcode = "Invalid format";
    }

    return newErrors;
  };

  const inputHandler = (fieldName) => {
    return (e) => {
      setValues({ ...values, [fieldName]: e.target.value });
    };
  };

  const formDataObject = new FormData();
  if (selectedImage) {
    formDataObject.append("profile_pic", selectedImage);
  }
  Object.keys(values).forEach((key) => {
    formDataObject.append(key, values[key]);
  });

  useEffect(() => {
    if (submit) {
      if (Object.keys(errors).length == 0) {
        dispatch(updateProfileAsync(formDataObject));
      }
      setSubmit(false);
    }
  }, [submit]);

  const handleSubmit = async () => {
    setErrors(validateForm(values));
    setSubmit(true);
  };

  const handleImage = (event) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const openFile = async () => {
    try {
      const fileHandle = await window.showOpenFilePicker();

      const file = await fileHandle.getFile();
      // Do something with the file
      if (file) {
        // Do something with the file
        console.log(file, "ffileeeeee");
      } else {
        console.error("Selected file is null or undefined.");
      }
    } catch (error) {
      console.error("Error opening file:", error);
    }
  };

  const handleClick = () => {
    document.getElementById("file-input").click();
  };

  const handleFileInput = (event) => {
    handleImage(event);
  };

  const handleDeleteUser = async () => {
    setLoading(true);
    setDeleteUser(false);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      await dispatch(
        deleteAccountAsync({ user_id: getprofile?.userProfile?.id })
      );
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MDBox sx={{ backgroundColor: "#f9f9f9", minHeight: "100vh" }}>
      <DashboardLayoutCustom>
        <DashboardNavbar />
        {(authLoading || loading) && <Loader />}
        <MDBox mb={2} sx={{ px: "1rem" }}>
          <MDBox>
            <MDBox>
              <MDBox
                sx={{
                  backgroundImage: ({
                    functions: { rgba, linearGradient },
                    palette: { gradients },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.info.main, 0.6),
                      rgba(gradients.info.state, 0.6)
                    )}, url(${backgroundImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "130px",
                  "@media(max-width:576px)": {
                    height: "0px",
                    marginTop: "30px",
                  },
                }}
              ></MDBox>

              <MDBox sx={{ position: "relative", backgroundColor: "#ffffff" }}>
                <Grid container spacing={3} alignItems="center">
                  <MDBox
                    sx={{
                      display: "flex",
                      position: "absolute",
                      left: "20px",
                      bottom: "20px",
                      alignItems: "end",
                    }}
                  >
                    <Grid item>
                      <MDBox sx={{ position: "relative" }}>
                        <MDAvatar
                          src={
                            previewImage
                              ? previewImage
                              : getprofile?.userProfile?.profilePicture
                              ? `${PROFILEURI}/${getprofile?.userProfile?.profilePicture}`
                              : burceMars
                          }
                          alt="profile-image"
                          size="xxl"
                          shadow="sm"
                        />
                        <input
                          type="file"
                          id="file-input"
                          style={{ display: "none" }}
                          disabled={!editState}
                          onChange={handleFileInput}
                        />
                        {editState && (
                          <MDBox
                            onClick={handleClick} // Attach click handler to MDBox for better UX
                            sx={{
                              backgroundColor: "#FFFFFF",
                              position: "absolute",
                              right: "0px",
                              bottom: "10px",
                              borderRadius: "50%",
                              width: "28px",
                              border: "1px solid #000",
                              height: "28px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                          >
                            <ModeEditSharpIcon className="ml-10" />
                          </MDBox>
                        )}
                      </MDBox>
                    </Grid>

                    <Grid item>
                      <MDBox height="100%" mt={0.5} lineHeight={1}>
                        <MDTypography
                          sx={{ paddingLeft: "15px" }}
                          variant="h5"
                          fontWeight="medium"
                        >
                          {firstname ? firstname : "Richard"}{" "}
                          {lastName ? lastName : "Davis"}
                        </MDTypography>
                        <MDTypography
                          sx={{ paddingLeft: "15px" }}
                          variant="button"
                          color="text"
                          fontWeight="regular"
                        >
                          @{firstname ? firstname.toLowerCase() : "richie"}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </MDBox>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    sx={{
                      ml: "auto",
                      "& button": { alignSelf: "end", color: "black" },
                    }}
                  >
                    <AppBar
                      position="static"
                      sx={{
                        pb: 1,
                        minHeight: "60px",
                        "@media(max-width:576px)": { minHeight: "125px" },
                      }}
                    >
                      <MDBox sx={{ display: "flex", justifyContent: "end" }}>
                        {!editState && (
                          <MDButton
                            onClick={() => setEditState(true)}
                            sx={{
                              color: "#ffffff",
                              mr: "1.5rem",
                              borderRadius: "45px",
                              fontFamily: "Urbanist",
                              fontSize: "16px",
                              fontWeight: 600,
                              lineHeight: "19.2px",
                              textTransform: "capitalize",
                              backgroundColor: "#318BEC",
                              border: "2px solid #318BEC",
                              "&:hover": {
                                border: "2px solid #318BEC",
                                backgroundColor: "#318BEC",
                                color: "#ffffff",
                                boxShadow:
                                  "0rem 0.875rem 1.625rem -0.75rem rgba(26, 115, 232, 0.4)",
                              },
                              "@media(max-width:576px)": {
                                fontSize: "0px",
                                lineHeight: "3px",
                                padding: 0,
                                maxWidth: "25px",
                              },
                            }}
                          >
                            <MDBox
                              sx={{
                                mr: 1,
                                "@media(max-width:576px)": {
                                  mr: 0,
                                },
                              }}
                            >
                              <img src={profileEditIcon} alt="add-icon" />
                            </MDBox>
                            Edit Profile
                          </MDButton>
                        )}
                        <MDButton
                          onClick={() => setDeleteUser(true)}
                          sx={{
                            color: "#ffffff",
                            mr: "1.5rem",
                            borderRadius: "45px",
                            fontFamily: "Urbanist",
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "19.2px",
                            textTransform: "capitalize",
                            backgroundColor: "#318BEC",
                            border: "2px solid #318BEC",
                            "&:hover": {
                              border: "2px solid #318BEC",
                              backgroundColor: "#318BEC!important",
                              color: "#ffffff",
                              boxShadow:
                                "0rem 0.875rem 1.625rem -0.75rem rgba(26, 115, 232, 0.4)",
                            },
                            "&:focus:not(:hover) ": {
                              border: "2px solid #318BEC",
                              backgroundColor: "#318BEC!important",
                              color: "#ffffff",
                              boxShadow:
                                "0rem 0.875rem 1.625rem -0.75rem rgba(26, 115, 232, 0.4)",
                            },
                            "@media(max-width:576px)": {
                              fontSize: "0px",
                              lineHeight: "3px",
                              padding: 0,
                              maxWidth: "25px",
                            },
                          }}
                        >
                          <MDBox
                            sx={{
                              mr: 1,
                              "@media(max-width:576px)": {
                                mr: 0,
                              },
                            }}
                          >
                             <img src={userdelete} alt="icon" />
                          </MDBox>
                          Delete Account
                        </MDButton>
                      </MDBox>
                    </AppBar>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox sx={{ px: 1.5 }}>
          <Grid container columnSpacing={4}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={1} p={1}>
                <MDTypography variant="subtitle2" fontWeight="medium" mt={1}>
                  First Name
                  <MDBox sx={{ color: "red" }} component="span">
                    *
                  </MDBox>
                </MDTypography>
                <MDInput
                  // variant="outlined"
                  type="text"
                  placeholder="Enter Contact First Name"
                  value={values.firstName}
                  error={!!errors.firstName}
                  onChange={inputHandler("firstName")}
                  fullWidth
                  disabled={!editState}
                  sx={{
                    "&.MuiFormControl-root": {
                      backgroundColor: "transparent !important",
                    },
                    "& .MuiInputBase-root": { borderRadius: "45px !important" },
                  }}
                />
              </MDBox>
              {errors.firstName ? (
                <MDTypography px={1} variant="button" color="error">
                  {errors.firstName}
                </MDTypography>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={1} p={1}>
                <MDTypography variant="subtitle2" fontWeight="medium" mt={1}>
                  Last Name
                  <MDBox sx={{ color: "red" }} component="span">
                    *
                  </MDBox>
                </MDTypography>
                <MDInput
                  error={!!errors.lastName}
                  type="text"
                  value={values.lastName}
                  placeholder="Enter Last Name"
                  onChange={inputHandler("lastName")}
                  fullWidth
                  disabled={!editState}
                  sx={{
                    "&.MuiFormControl-root": {
                      backgroundColor: "transparent !important",
                    },
                    "& .MuiInputBase-root": { borderRadius: "45px !important" },
                  }}
                />
              </MDBox>

              {errors.lastName ? (
                <MDTypography px={1} variant="button" color="error">
                  {errors.lastName}
                </MDTypography>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={1} p={1}>
                <MDTypography variant="subtitle2" fontWeight="medium" mt={1}>
                  Contact Number
                  <MDBox sx={{ color: "red" }} component="span">
                    *
                  </MDBox>
                </MDTypography>
                {editState ? (
                  <ReactInputMask
                    mask="(999) 999-9999"
                    value={values.phoneNumber}
                    onChange={inputHandler("phoneNumber")}
                    maskChar=" "
                    disabled={false}
                  >
                    {(inputProps) => (
                      <MDInput
                        placeholder="contact number"
                        error={!!errors.phoneNumber}
                        fullWidth
                        {...inputProps}
                        sx={{
                          "&.MuiFormControl-root": {
                            backgroundColor: "transparent !important",
                          },
                          "& .MuiInputBase-root": {
                            borderRadius: "45px !important",
                          },
                        }}
                      />
                    )}
                  </ReactInputMask>
                ) : (
                  <MDInput
                    placeholder="contact number"
                    value={values.phoneNumber}
                    onChange={inputHandler("phoneNumber")}
                    error={!!errors.phoneNumber}
                    fullWidth
                    disabled
                    sx={{
                      "&.MuiFormControl-root": {
                        backgroundColor: "transparent !important",
                      },
                      "& .MuiInputBase-root": {
                        borderRadius: "45px !important",
                      },
                    }}
                  />
                )}
                {/* <MDInput
                error={!!errors.phoneNumber}
                type="number"
                value={values.phoneNumber}
                placeholder="Phone no"
                onChange={inputHandler("phoneNumber")}
                fullWidth
                disabled={!editState}
              /> */}
              </MDBox>
              {errors.phoneNumber ? (
                <MDTypography px={1} variant="button" color="error">
                  {errors.phoneNumber}
                </MDTypography>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox p={1} mt={1}>
                <MDTypography variant="subtitle2" fontWeight="medium" mt={1}>
                  Address
                  <MDBox sx={{ color: "red" }} component="span">
                    *
                  </MDBox>
                </MDTypography>
                <MDInput
                  error={!!errors.address}
                  type="text"
                  value={values.address}
                  placeholder="Address"
                  onChange={inputHandler("address")}
                  fullWidth
                  disabled={!editState}
                  sx={{
                    "&.MuiFormControl-root": {
                      backgroundColor: "transparent !important",
                    },
                    "& .MuiInputBase-root": { borderRadius: "45px !important" },
                  }}
                />
              </MDBox>
              {errors.address ? (
                <MDTypography px={1} variant="button" color="error">
                  {errors.address}
                </MDTypography>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox p={1} mt={1}>
                <MDTypography variant="subtitle2" fontWeight="medium" mt={1}>
                  Country
                  <MDBox sx={{ color: "red" }} component="span">
                    *
                  </MDBox>
                </MDTypography>
                <MDInput
                  error={!!errors.country}
                  type="text"
                  value={values.country}
                  placeholder="country"
                  onChange={inputHandler("country")}
                  fullWidth
                  disabled={!editState}
                  QA
                  sx={{
                    "&.MuiFormControl-root": {
                      backgroundColor: "transparent !important",
                    },
                    "& .MuiInputBase-root": { borderRadius: "45px !important" },
                  }}
                />
              </MDBox>
              {errors.country ? (
                <MDTypography px={1} variant="button" color="error">
                  {errors.country}
                </MDTypography>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox p={1} mt={1}>
                <MDTypography variant="subtitle2" fontWeight="medium" mt={1}>
                  State
                  <MDBox sx={{ color: "red" }} component="span">
                    *
                  </MDBox>
                </MDTypography>
                <MDInput
                  error={!!errors.state}
                  type="text"
                  value={values.state}
                  placeholder="state"
                  onChange={inputHandler("state")}
                  fullWidth
                  disabled={!editState}
                  sx={{
                    "&.MuiFormControl-root": {
                      backgroundColor: "transparent !important",
                    },
                    "& .MuiInputBase-root": { borderRadius: "45px !important" },
                  }}
                />
              </MDBox>
              {errors.state ? (
                <MDTypography px={1} variant="button" color="error">
                  {errors.state}
                </MDTypography>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox p={1} mt={1}>
                <MDTypography variant="subtitle2" fontWeight="medium" mt={1}>
                  City
                  <MDBox sx={{ color: "red" }} component="span">
                    *
                  </MDBox>
                </MDTypography>
                <MDInput
                  error={!!errors.city}
                  type="text"
                  value={values.city}
                  placeholder="city"
                  onChange={inputHandler("city")}
                  fullWidth
                  disabled={!editState}
                  sx={{
                    "&.MuiFormControl-root": {
                      backgroundColor: "transparent !important",
                    },
                    "& .MuiInputBase-root": { borderRadius: "45px !important" },
                  }}
                />
              </MDBox>
              {errors.city ? (
                <MDTypography px={1} variant="button" color="error">
                  {errors.city}
                </MDTypography>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox p={1} mt={1}>
                <MDTypography variant="subtitle2" fontWeight="medium" mt={1}>
                  Zip/ Postel code
                  <MDBox sx={{ color: "red" }} component="span">
                    *
                  </MDBox>
                </MDTypography>
                <MDInput
                  error={!!errors.zipcode}
                  type="text"
                  value={values.zipcode}
                  placeholder="012311"
                  onChange={inputHandler("zipcode")}
                  fullWidth
                  disabled={!editState}
                  sx={{
                    "&.MuiFormControl-root": {
                      backgroundColor: "transparent !important",
                    },
                    "& .MuiInputBase-root": { borderRadius: "45px !important" },
                  }}
                />
              </MDBox>
              {errors.zipcode ? (
                <MDTypography px={1} variant="button" color="error">
                  {errors.zipcode}
                </MDTypography>
              ) : null}
            </Grid>
          </Grid>
          {editState && (
            <MDBox
              sx={{
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
                py: 5,
              }}
            >
              <MDButton
                elevation={0}
                color="info"
                onClick={() => handleSubmit()}
                sx={{
                  paddingLeft: "5rem",
                  paddingRight: "5rem",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  borderRadius: "45px",
                }}
              >
                Submit
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </DashboardLayoutCustom>
      <Modal
        open={deleteUser}
        onClose={() => setDeleteUser(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          <MDBox sx={{ position: "relative", pt: "15px" }}>
            <MDBox
              sx={{
                position: "absolute",
                top: "-15px",
                left: "38%",
              }}
            ></MDBox>
            <MDBox>
              <h2
                id="modal-modal-title"
                style={{
                  fontFamily: "urbanist",
                  fontSize: "24px",
                  fontWeight: 700,
                  color: "#333",
                  textAlign: "center",
                }}
              >
                Confirm Account Deletion
              </h2>
              <p
                id="modal-modal-description"
                style={{
                  fontFamily: "urbanist",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "30px",
                  marginTop: "14px",
                  color: "#818181",
                  textAlign: "center",
                }}
              >
                Are you sure you want to delete your account? This action is
                permanent and cannot be undone. All your data will be
                permanently removed.
              </p>
            </MDBox>

            <MDButton
              variant="gradient"
              color="info"
              sx={{
                paddingLeft: "4rem",
                paddingRight: "4rem",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                borderRadius: "45px",
                marginTop: "30px",
              }}
              onClick={() => {
                setDeleteUser(false);
              }}
            >
              Cancel
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              sx={{
                paddingLeft: "4rem",
                paddingRight: "4rem",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                borderRadius: "45px",
                marginTop: "30px",
                marginLeft: "16px",
              }}
              onClick={handleDeleteUser}
            >
              Delete
            </MDButton>
          </MDBox>
        </MDBox>
      </Modal>
    </MDBox>
  );
}

export default Overview;
